import React, { Fragment } from "react"
import Flex from "../Flex"
import Box from "../Box"
import Paper from "../Paper"
import { List, ListItem, ListItemText } from "../lists"
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Switch from "../Switch"

export default function UserSectors(props) {

  function handleSectorSwitchChange(e, userSector) {
    props.handleSectorSwitchChange(e.target.value, userSector)
  }

  return(
    <Fragment>
      <Box width={1/4} mt={3}>
        <List>
          {props.sectors.map((sector) => {
            const userSector = props.userSectors.filter(userSector => userSector.sector_id == sector.id)[0]
            return (
              <ListItem key={sector.id} button>
                <ListItemText
                  primary={sector.abbr}
              />

                <ListItemSecondaryAction>
                  <Switch
                    edge="end"
                    disabled={!props.userCompanySectors.includes(sector.id)}
                    checked={userSector && !userSector.deleted ? true : false}
                    handleChange={(e) => handleSectorSwitchChange(e, userSector)}
                    value={userSector ? userSector.id : null}
                  />
                </ListItemSecondaryAction>

              </ListItem>
            )
          })}
        </List>
      </Box>
    </Fragment>
  )
}
