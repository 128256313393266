import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import AppBar from "./AppBar"
import Link from "./Link"
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from './IconButton';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import List from './lists/List'
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from "./lists/ListItemText";
import ListItemLink from "./lists/ListItemLink";
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SearchIcon from '@material-ui/icons/Search'
import HelpIcon from '@material-ui/icons/Help';
import LogoutIcon from "./LogoutIcon"
import Tabs from './tabs/Tabs';
import Tab from './tabs/Tab';
import Flex from "./Flex"
import Box from "./Box"
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PeopleIcon from '@material-ui/icons/People';

const drawerWidth = 180;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor: '#fff'
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  title: {
    flexGrow: 0.5,
    marginLeft: '1rem',
  },
  logo: {
    flexGrow: 0.5,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end',
    minHeight: '51px',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

export default function UserSidebar(props) {
  const classes = useStyles();

  return (
    <Fragment>

        <Drawer
          className={classes.drawer}
          variant={props.variant}
          anchor="left"
          open={props.showSidebar}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={props.handleDrawerClose} tooltip='Hide Sidebar'>
              <MenuIcon />
            </IconButton>
          </div>
          <Divider />

          <Flex flexDirection='column' flexGrow={0.8}>
            <Box>
              <List>

                <ListItemLink href={`/sectors/${props.sector.abbr}/dashboard`} active={props.activeItem == 'Home' || props.activeGroup == 'Home'}>
                  <ListItemText primary='Home' />
                </ListItemLink>

                <ListItemLink href={`/sectors/${props.sector.abbr}/companies`}  active={props.activeItem == 'Companies' || props.activeGroup == 'Companies'}>
                  <ListItemText primary='Companies' />
                </ListItemLink>

                <ListItemLink href={`/sectors/${props.sector.abbr}/products`}  active={props.activeItem == 'Products' || props.activeGroup == 'Products'}>
                  <ListItemText primary='Products' />
                </ListItemLink>

                <ListItemLink href={`/sectors/${props.sector.abbr}/trials`}  active={props.activeItem == 'Trials' || props.activeGroup == 'Trials'}>
                  <ListItemText primary='Trials' />
                </ListItemLink>

                <ListItemLink href={`/sectors/${props.sector.abbr}/people`}  active={props.activeItem == 'People' || props.activeGroup == 'People'}>
                  <ListItemText primary='People' />
                </ListItemLink>

                <ListItemLink href={`/sectors/${props.sector.abbr}/abstracts`}  active={props.activeItem == 'Presentations' || props.activeGroup == 'Presentations'}>
                  <ListItemText primary='Presentations' />
                </ListItemLink>

                {/* <ListItemLink href={`/sectors/${props.sector.abbr}/meetings`}  active={props.activeItem == 'Meetings' || props.activeGroup == 'Meetings'}>
                  <ListItemText primary='Meetings' />
                </ListItemLink> */}

                <ListItemLink href={`/sectors/${props.sector.abbr}/publications`}  active={props.activeItem == 'Publications' || props.activeGroup == 'Publications'}>
                  <ListItemText primary='Publications' />
                </ListItemLink>


                <ListItemLink href={`/sectors/${props.sector.abbr}/patents`}  active={props.activeItem == 'Patents' || props.activeGroup == 'Patents'}>
                  <ListItemText primary='Patents' />
                </ListItemLink>

                <ListItemLink href={`/sectors/${props.sector.abbr}/alerts`}  active={props.activeItem == 'Alerts' || props.activeGroup == 'Alerts'}>
                  <ListItemText primary='Alerts' />
                </ListItemLink>

                {props.currentUser.user_company_id == 1 ? 
                  <ListItemLink href={`/sectors/${props.sector.abbr}/regulatory`}  active={props.activeItem == 'Regulatory' || props.activeGroup == 'Regulatory'}>
                    <ListItemText primary='Regulatory' />
                  </ListItemLink>
                : ""}

                {props.currentUser.user_company_id == 1 ?
                  <ListItemLink href={`/sectors/${props.sector.abbr}/news`}  active={props.activeItem == 'News' || props.activeGroup == 'News'}>
                    <ListItemText primary='News' />
                  </ListItemLink>
                : ""}

                {/* <ListItemLink href={`/sectors/${props.sector.abbr}/navigator`}  active={props.activeItem == 'Navigator' || props.activeGroup == 'Navigator'}>
                  <ListItemText primary='Navigator' />
                </ListItemLink> */}

                {props.currentUser.user_company_id == 1 ?
                  <ListItemLink href={`/sectors/${props.sector.abbr}/tweets`}  active={props.activeItem == 'Tweets' || props.activeGroup == 'Tweets'}>
                    <ListItemText primary='Twitter' />
                  </ListItemLink>
                : ""}


                {props.currentUser.user_company_id == 1 ? <ListItemLink href='/admin'>
                  <ListItemText primary='Admin' />
                </ListItemLink> : ''}

              </List>
            </Box>
          </Flex>

          <Divider />

          <List>
            <ListItemLink href='/users/support' tag='a'>
              <IconButton>
                <HelpIcon />
              </IconButton>
              <ListItemText primary='Feedback' />
            </ListItemLink>
            {props.currentUser.customer_admin || props.currentUser.user_company_id == 1 ? 
              <ListItemLink href='/user_management/dashboard' tag='a'>
                <ListItemIcon>
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary='User Management' />
              </ListItemLink>
            : null}
            <ListItemLink href='/users/settings' tag='a'>
              <IconButton>
                <AccountCircleIcon />
              </IconButton>
              <ListItemText primary='Settings' />
            </ListItemLink>
            <ListItemLink href='/logout' tag='a'>
              <IconButton>
                <ExitToAppIcon />
              </IconButton>
              <ListItemText primary='Logout' />
            </ListItemLink>
          </List>
        </Drawer>

    </Fragment>
  );
}
