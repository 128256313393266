import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Box,
  Chip,
  Button,
  Tooltip,
  IconButton
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { formatDistanceToNow } from 'date-fns';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  header: {
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  formControl: {
    minWidth: 200
  },
  chip: {
    borderRadius: 4
  },
  reviewButton: {
    textTransform: 'none'
  },
  headerCell: {
    display: 'flex',
    alignItems: 'center'
  },
  helpIcon: {
    fontSize: '1rem',
    marginLeft: theme.spacing(0.5),
    color: theme.palette.text.secondary
  }
}));

const tooltips = {
  precision: "Precision measures how accurate the search term is at finding relevant content. It's calculated as (accepted matches) / (total matches). Higher is better.",
  rejectionRate: "The percentage of matches that were marked as incorrect or irrelevant. A high rejection rate may indicate the search term is too broad or needs refinement.",
  totalMatches: "The total number of content items (manuscripts, trials, news) that matched this search term.",
  status: "The current review status of the search term. Terms marked as 'Needs Review' require attention due to low precision or high rejection rates.",
  markedForReview: "When this search term was flagged for review, either automatically due to performance metrics or manually by an administrator."
};

const HeaderWithTooltip = ({ label, tooltip, classes }) => (
  <div className={classes.headerCell}>
    {label}
    <Tooltip title={tooltip} placement="top">
      <IconButton size="small">
        <HelpOutlineIcon className={classes.helpIcon} />
      </IconButton>
    </Tooltip>
  </div>
);

const SearchTermReviewQueue = ({ reviewQueue: initialReviewQueue, products: initialProducts }) => {
  const classes = useStyles();
  const [filteredQueue, setFilteredQueue] = useState(initialReviewQueue);
  const [selectedProduct, setSelectedProduct] = useState('');

  const handleProductChange = (event) => {
    const productId = event.target.value;
    setSelectedProduct(productId);
    
    if (productId) {
      setFilteredQueue(initialReviewQueue.filter(term => term.product.id === productId));
    } else {
      setFilteredQueue(initialReviewQueue);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'needs_review':
        return 'secondary';
      case 'under_review':
        return 'default';
      case 'reviewed':
        return 'primary';
      default:
        return 'default';
    }
  };

  const formatPrecision = (precision) => {
    return precision ? `${(precision * 100).toFixed(1)}%` : 'N/A';
  };

  const formatRejectionRate = (rate) => {
    return rate ? `${(rate * 100).toFixed(1)}%` : 'N/A';
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h5" component="h1">
          Search Term Review Queue
        </Typography>
        <FormControl className={classes.formControl}>
          <InputLabel>Filter by Product</InputLabel>
          <Select
            value={selectedProduct}
            onChange={handleProductChange}
            label="Filter by Product"
          >
            <MenuItem value="">All Products</MenuItem>
            {initialProducts.map((product) => (
              <MenuItem key={product.id} value={product.id}>
                {product.short_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Search Term</TableCell>
              <TableCell>Product</TableCell>
              <TableCell>
                <HeaderWithTooltip 
                  label="Status" 
                  tooltip={tooltips.status}
                  classes={classes}
                />
              </TableCell>
              <TableCell>
                <HeaderWithTooltip 
                  label="Precision" 
                  tooltip={tooltips.precision}
                  classes={classes}
                />
              </TableCell>
              <TableCell>
                <HeaderWithTooltip 
                  label="Rejection Rate" 
                  tooltip={tooltips.rejectionRate}
                  classes={classes}
                />
              </TableCell>
              <TableCell>
                <HeaderWithTooltip 
                  label="Total Matches" 
                  tooltip={tooltips.totalMatches}
                  classes={classes}
                />
              </TableCell>
              <TableCell>
                <HeaderWithTooltip 
                  label="Marked For Review" 
                  tooltip={tooltips.markedForReview}
                  classes={classes}
                />
              </TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredQueue.length === 0 ? (
              <TableRow>
                <TableCell colSpan={8} align="center">No search terms need review</TableCell>
              </TableRow>
            ) : (
              filteredQueue.map((term) => (
                <TableRow key={term.id}>
                  <TableCell>
                    <Tooltip title={term.review_reason || 'No review reason provided'} placement="top">
                      <span>{term.search_term}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell>{term.product.name}</TableCell>
                  <TableCell>
                    <Chip
                      label={term.review_status?.replace('_', ' ').toUpperCase() || 'NEEDS REVIEW'}
                      color={getStatusColor(term.review_status)}
                      size="small"
                      className={classes.chip}
                    />
                  </TableCell>
                  <TableCell>{formatPrecision(term.stats.precision)}</TableCell>
                  <TableCell>{formatRejectionRate(term.stats.rejection_rate)}</TableCell>
                  <TableCell>{term.stats.total_matches}</TableCell>
                  <TableCell>
                    {term.marked_at ? formatDistanceToNow(new Date(term.marked_at), { addSuffix: true }) : 'N/A'}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      className={classes.reviewButton}
                      href={`/admin/products/${term.product.id}/product_search_terms/${term.id}/edit`}
                    >
                      Review
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default SearchTermReviewQueue; 