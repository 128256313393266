import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import TextInput from "../../TextInput"
import Link from "../../Link"
import Switch from "../../Switch"
import RaisedButton from "../../buttons/RaisedButton"
import ButtonLayout from "../ButtonLayout"


class ProfileForm extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <Fragment>
        <div className='mb4'>
          <TextInput
            name='full_name'
            label='Full Name*'
            helperText='*Required'
            persistent={this.props.newCompany ? true : false}
            value={this.props.company.full_name}
            onChange={this.props.handleChange}
            fullWidth
          />
        </div>

        <div className='mb4'>
          <TextInput
            name='short_name'
            label='Short Name'
            value={this.props.company.short_name}
            onChange={this.props.handleChange}
            fullWidth
          />
        </div>

        <div className='mb4'>
          <TextInput
            name='url'
            label='URL'
            type='url'
            helperText='Copy & paste url from company site'
            value={this.props.company.url}
            onChange={this.props.handleChange}
            fullWidth
          />
        </div>

        <div className='mb4'>
          <TextInput
            name='headquarters'
            label='Headquarters'
            value={this.props.company.headquarters}
            onChange={this.props.handleChange}
            fullWidth
          />
        </div>

        <div className='mb4'>
          <label className='mr3'>Public</label>
          <Switch
            name='public'
            checked={this.props.company.public}
            handleChange={this.props.handlePublicChange}
          />
        </div>

        <div className='mb4'>
          <label className='mr3'>Archived</label>
          <Switch
            name='archived'
            checked={this.props.company.archived}
            handleChange={this.props.handleSwitchChange}
          />
        </div>

        <div className='mb4'>
          <label className='mr3'>Deleted</label>
          <Switch
            name='deleted'
            checked={this.props.company.deleted}
            handleChange={this.props.handleSwitchChange}
          />
        </div>

        <ButtonLayout>
          <RaisedButton handleClick={this.props.handleSaveClick}>
            Save Company
          </RaisedButton>
        </ButtonLayout>

      </Fragment>
    )
  }
}

export default ProfileForm
