import React, { Fragment } from 'react';
import { 
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import hubAndSpoke from "../../../assets/images/hub_and_spoke.png";
import Flex from "../Flex";
import Box from "../Box";
import Form from "./Form";

const useStyles = makeStyles((theme) => ({
  topBlock: {
    backgroundColor: '#FBF7ED',
    padding: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  diagram: {
    maxWidth: '300px',
    width: '100%',
    height: 'auto',
  },
  quote: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    backgroundColor: '#F0F5FA',
    padding: theme.spacing(4),
    '&::before': {
      position: 'absolute',
      top: 0,
      left: 0,
      fontSize: '3rem',
      lineHeight: 1,
      color: theme.palette.grey[200],
      fontFamily: 'Georgia, serif',
    }
  },
  quoteText: {
    fontStyle: 'italic',
    marginLeft: theme.spacing(4),
  },
  quoteAuthor: {
    marginTop: theme.spacing(2),
    color: theme.palette.text.secondary,
    fontWeight: 500,
  },
  contentSpacing: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    // maxWidth: '600px',
    // margin: '0 auto',
  },
  gridContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  }
}));

export default function EdwardsSignupPage({ userCompany, authorizedDomain, form }) {
  const classes = useStyles();

  const formatExpirationDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { 
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    });
  };

  return (
    <Fragment>
        <div className={classes.topBlock}>
          <Typography variant="h4" color="primary" gutterBottom>
            Welcome!
          </Typography>

          <p>As an Edwards employee, you have free access to CVPipeline for a limited trial period.</p>

          <p>CVPipeline is a comprehensive database of product development.  Since 2006, hundreds of Edwards users have subscribed to our Heart Valves and CHF sectors, many for more than 10 years.</p>

          <p>Now you can see if CVPipeline could be valuable to you.</p>
        </div>

        <Grid container spacing={3} className={classes.gridContainer}>
          <Grid item xs={12} md={5}>
            <img 
              src={hubAndSpoke} 
              alt="CVPipeline Database Connections"
              className={classes.diagram}
            />
          </Grid>

          <Grid item xs={12} md={7}>
            <Box className={classes.quote}>
              <Typography variant="body1" className={classes.quoteText}>
                There's an enormous amount of data, but the real secret sauce of CVPipeline 
                is the way all of the information is connected, making it easy to navigate through 
                everything related to a product's development status.
              </Typography>
              <Typography variant="subtitle2" className={classes.quoteAuthor}>
                — a CVPipeline user
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Box className={classes.contentSpacing}>
          <Typography variant="body1" gutterBottom>
            It's very easy. Sign up by entering your information below. You will receive an email back confirming your account creation.
          </Typography>
        </Box>

        <Flex flexDirection="row" justifyContent="space-between" alignItems="center">
          <Box width="40%">
            {form}
          </Box>

          <Box width="45%" my={0} mx="auto">
            <Box>
              <Typography variant="h6" color="primary" gutterBottom>
                We're excited to have you join the CVPipeline community!
              </Typography>
            </Box>

            <Box mt={2}>
              <Typography variant="caption" color="textSecondary">
                {authorizedDomain?.expires_at && 
                  `Your trial account will automatically expire on ${formatExpirationDate(authorizedDomain.expires_at)}.`
                }
              
                {userCompany?.account_contact_name && 
                  `Please contact ${userCompany.account_contact_name} (${userCompany.account_contact_email}) if you would 
                  like more information about subscribing to CVPipeline.`
                }
              </Typography>
            </Box>
          </Box>
        </Flex>
      </Fragment>
  );
}

