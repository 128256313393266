import React from 'react';
import { 
  Container, 
  Typography, 
  Box, 
  Button,
  Grid,
  makeStyles 
} from '@material-ui/core';
import Paper from "../Paper"
import hubAndSpoke from "../../../assets/images/hub_and_spoke.png"

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(4),
  },
  paper: {
    textAlign: 'center',
  },
  diagram: {
    maxWidth: '300px',
    width: '100%',
    height: 'auto',
  },
  quote: {
    padding: theme.spacing(3),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    // Subtle left border instead of background color
    borderLeft: `4px solid ${theme.palette.grey[200]}`,
    // Optional: add a very subtle background if desired
    // backgroundColor: theme.palette.grey[50],
  },
  ctaButton: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(1.5, 4),
    fontSize: '1rem',
    color: 'white',
    '&:hover': {
      color: 'white',
    },
  },
  contentSpacing: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    maxWidth: '600px',
    margin: '0 auto',
  },
  gridContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  }
}));

const WelcomePage = (props) => {
  const classes = useStyles();

  const userCompany = props.user_company;
  const signupToken = props.signup_token;
  const authorizedDomain = props.authorized_domain;
  const userCompanySectors = props.user_company_sectors;

  const formatExpirationDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { 
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    });
  };

  return (
    <Container maxWidth="lg" className={classes.root}>
      <Paper className={classes.paper}>
        <Typography variant="h4" color="primary" gutterBottom>
          {`Welcome ${userCompany.name} employees!`}
        </Typography>
        
        <Typography variant="h6" gutterBottom>
          You have free access to CVPipeline for a limited trial period.
        </Typography>

        <Box className={classes.contentSpacing}>
          <Typography variant="body1">
            Since 2006, hundreds of {userCompany.name} users have subscribed to our sectors, 
            many for more than 10 years.
          </Typography>
        </Box>

        <Grid container spacing={3} className={classes.gridContainer}>
          <Grid item xs={12} md={5}>
            <img 
              src={hubAndSpoke} 
              alt="CVPipeline Database Connections"
              className={classes.diagram}
            />
          </Grid>

          <Grid item xs={12} md={7}>
            <Box className={classes.quote}>
              <Typography variant="body1" style={{ fontStyle: 'italic' }}>
                "There's an enormous amount of data, but the real secret sauce of CVPipeline 
                is the way all of the information is connected, making it easy to navigate through 
                everything related to a product's development status."
              </Typography>
              <Typography variant="subtitle2" color="textSecondary" style={{ marginTop: 8 }}>
                — a CVPipeline user
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Box className={classes.contentSpacing}>
          <Typography variant="body1" gutterBottom>
            Creating your account is simple. Click the button below to register and get access to CVPipeline.
          </Typography>

          <Button 
            variant="contained" 
            color="primary"
            component='a'
            href={`/sign_up/${signupToken.token}`}
            className={classes.ctaButton}
          >
            Create Your Trial Account
          </Button>

          <Box mt={2}>
            <Typography variant="caption" color="textSecondary">
              {authorizedDomain && authorizedDomain.expires_at && 
                `Your trial account will automatically expire on ${formatExpirationDate(authorizedDomain.expires_at)}.`
              }
              
              {userCompany.account_contact_name && 
                `Please contact ${userCompany.account_contact_name} (${userCompany.account_contact_email}) if you would 
                like more information about subscribing to CVPipeline.`
              }
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default WelcomePage;
