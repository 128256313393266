import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { Table, TableHead, TableRow, TableBody, TableCell } from "../tables"
import TrialRow from "./TrialRow"
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from "../tables/TablePaginationActions"
import Box from "../Box"
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableHeadSort from "../tables/TableHeadSort"
import IconButton from "../IconButton"
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import LinearProgress from '@material-ui/core/LinearProgress';

export default class TrialsTable extends React.PureComponent {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <Box overflow='auto' height={this.props.height || "80%"}>
        <Table size='small' aria-label='trials table' stickyHeader={true} id='trials-table'>

          <TableHeadSort
            headers={[{label: <IconButton onClick={this.props.handleBulkTrialCheck} tooltip='Select' edge="start">{this.props.checkedTrials.length > 0 ? <IndeterminateCheckBoxIcon style={{height: '22px', width: '22px'}} /> : <CheckBoxOutlineBlankIcon style={{height: '22px', width: '22px'}} />}</IconButton>, name: '', noSort: true, checkbox: true }, { name: 'short_name', label: 'Trial' }, { name: 'sponsor', label: 'Sponsor' }, { name: 'type', label: 'Type' }, { name: 'updated_at', label: 'Updated', align: 'right' }]}
            order={this.props.order}
            orderBy={this.props.orderBy}
            onRequestSort={this.props.handleRequestSort}
          />

          {/* <Box width={1}>
            <LinearProgress />
          </Box> */}

          <TableBody>
            <React.Fragment>
              {this.props.loading ?
                <TableRow hover={false}>
                  <TableCell noBorder colSpan={5}>
                    <LinearProgress />
                  </TableCell>
                </TableRow>
              : ""}
              {this.props.trials.map((trial) => {
                const trialData = trial._source || trial
                return (
                  <TrialRow
                    key={`${trial.id}-${this.props.restOfKey}`}
                    trial={trialData}
                    sector={this.props.sector}
                    choose={this.props.choose}
                    expandedTrial={this.props.expandedTrial}
                    checkedTrials={this.props.checkedTrials}
                    handleTrialCheckboxClick={this.props.handleTrialCheckboxClick}
                    handleExpandClick={this.props.handleExpandClick}
                    handleFavoriteClick={this.props.handleFavoriteClick}
                  />
                )
              })}
            </React.Fragment>
          </TableBody>
          <TableFooter>
            <TablePagination
              colSpan={5}
              count={this.props.trialsCount}
              rowsPerPage={100}
              page={this.props.page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onChangePage={this.props.handleChangePage}
              onChangeRowsPerPage={this.props.handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableFooter>
        </Table>
      </Box>
    )
  }
}
