import React, { Component, Fragment } from "react"
import CompaniesPanel from "./CompaniesPanel"
import ControlPanel from "../associator/ControlPanel"
import PatentPanel from "./PatentPanel"
import Snackbar from "../../Snackbar"
import update from "immutability-helper"
import Associator from "../Associator"
import EntitiesPanel from "./EntitiesPanel"
import SectorCheckboxList from "../associator/SectorCheckboxList"


export default class AssociatorContainer extends Component {
  constructor(props) {
    super(props)
    this.handleAssociateClick = this.handleAssociateClick.bind(this)
    this.handleUnassociateClick = this.handleUnassociateClick.bind(this)
    this.handleSectorCheckboxSelect = this.handleSectorCheckboxSelect.bind(this)
    this.handleCheckboxSelect = this.handleCheckboxSelect.bind(this)
    this.handleEntityIndexUpdate = this.handleEntityIndexUpdate.bind(this)
    this.handleCompanySearchChange = this.handleCompanySearchChange.bind(this)
    this.handleCompanySearchClick = this.handleCompanySearchClick.bind(this)
    this.handleAliasCreationClick = this.handleAliasCreationClick.bind(this)
    this.handleReviewedClick = this.handleReviewedClick.bind(this)
    this.state = { patent: this.props.patent, patentCompany: this.props.patent_company, patentSectors: this.props.patent_sectors, companyId: null, entityIndex: 'associated', searchedCompanies: [], companyQuery: '', patentReviewStatus: this.props.patent_review_status, disableAssociate: true }
  }

  handleAliasCreationClick(e) {
    $.ajax({
      type: 'POST',
      url: '/admin/company_aliases',
      data: {
        company_alias: {
          company_id: this.state.patentCompany.company_id, name: this.props.assignee, created_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'Alias was created'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'Alias could not be created'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false
          })
        }, 4000)
      }
    })
  }


  handleCompanySearchChange(e) {
    this.setState({
      companyQuery: e.target.value
    })
  }

  handleCompanySearchClick(e) {
    $.ajax({
      type: 'GET',
      url: '/admin/companies/search',
      data: {
        query: this.state.companyQuery
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          searchedCompanies: data.companies
        })
      }
    })
  }

  handleEntityIndexUpdate(e, newValue) {
    this.setState({
      entityIndex: newValue
    })
  }

  handleCheckboxSelect(company) {

    this.setState({
      companyId: company.id,
      disableAssociate: false
    })
  }

  handleUnassociateClick(e) {
    $.ajax({
      type: 'PUT',
      url: `/admin/patent_companies/${this.state.patentCompany.id}`,
      data: {
        patent_company: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          patentCompany: null
        })
      }
    })
  }

  handleSectorCheckboxSelect(sector, checked) {
    console.log(checked)

    let patentSector = this.state.patentSectors.filter(patentSector => patentSector.sector_id == sector.id)[0]

    if (patentSector) {
      $.ajax({
        type: 'PUT',
        url: `/admin/patent_sectors/${patentSector.id}`,
        data: {
          patent_sector: {
            deleted: true, deleted_by: this.props.current_user.id
          }
        },
        dataType: 'JSON',
        success: (data) => {
          let index = this.state.patentSectors.indexOf(sector)

          let patentSectors = update(this.state.patentSectors, {
            $splice: [[index, 1]]
          })

          this.setState({
            patentSectors: patentSectors,
            showSnackbar: true,
            snackbarText: 'The sector was removed'
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: 'The sector could not be removed'
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    } else {
      $.ajax({
        type: 'POST',
        url: `/admin/patent_sectors`,
        data: {
          patent_sector: {
            patent_id: this.props.patent.id, sector_id: sector.id, created_by: this.props.current_user.id
          }
        },
        dataType: 'json',
        success: (data) => {
          let patentSectors = update(this.state.patentSectors, {
            $push: [data.patent_sector]
          })

          this.setState({
            patentSectors: patentSectors,
            showSnackbar: true,
            snackbarText: 'The sector was added'
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: 'The sector could not be added'
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    }
  }

  handleAssociateClick(e) {
    $.ajax({
      type: 'POST',
      url: '/admin/patent_companies',
      data: {
        patent_company: {
          patent_id: this.props.patent.id, company_id: this.state.companyId, created_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          patentCompany: data.patent_company,
          showSnackbar: true,
          snackbarText: 'Company was associated'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: false,
          snackbarText: 'Company could not be associated'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        })
      }
    })
  }

  handleReviewedClick = (reviewed) => {
    this.setState({ associationLoading: true })

    $.ajax({
      url: `/admin/patents/${this.props.patent.id}`,
      type: 'PUT',
      data: {
        patent: {
          reviewed_at: reviewed ? new Date().toISOString() : null,
          reviewed_by: reviewed ? this.props.current_user.id : null
        }
      },
      success: (response) => {
        this.setState({
          patent: response.patent,
          reviewed: reviewed,
          associationLoading: false,
          showSnackbar: true,
          snackbarText: reviewed ? 'Patent marked as reviewed' : 'Review status removed'
        })
      },
      error: () => {
        this.setState({
          associationLoading: false,
          showSnackbar: true,
          snackbarText: 'Failed to update review status'
        })
      }
    })
  }

  render() {
    return(
      <Fragment>

        <Associator
          loading={this.state.loading}
          firstPanel={
            <PatentPanel
              patent={this.props.patent}
              descriptionText={this.props.description_text}
              claimText={this.props.claim_text}
              searchTerms={this.props.search_terms}
              assignee={this.props.assignee}
              applicants={this.props.applicants}
              classifications={this.props.classifications}
              activePatents={this.state.activePatents}
              handlePatentCardClick={this.handlePatentCardClick}
            />

          }
          secondPanel={
            <Fragment>
              <ControlPanel
                associatedCompany={this.state.patentCompany}
                reviewed={this.state.patent.reviewed_at}
                disableAssociate={this.state.disableAssociate}
                handleAssociateClick={this.handleAssociateClick}
                handleAliasCreationClick={this.handleAliasCreationClick}
                handleReviewedClick={this.handleReviewedClick}
              />

              <SectorCheckboxList
                sectors={this.props.sectors}
                entitySectors={this.state.patentSectors}
                predictedSectors={this.props.patent_sector_predictions}
                handleSectorCheckboxSelect={this.handleSectorCheckboxSelect}
              />
            </Fragment>
          }
          thirdPanel={
            <EntitiesPanel
              companyId={this.state.companyId}
              companies={this.props.companies}
              patentCompany={this.state.patentCompany}
              activeIndex={this.state.entityIndex}
              searchedCompanies={this.state.searchedCompanies}
              companyQuery={this.state.companyQuery}
              handleCompanySearchChange={this.handleCompanySearchChange}
              handleCompanySearchClick={this.handleCompanySearchClick}
              handleActiveIndexUpdate={this.handleEntityIndexUpdate}
              handleCheckboxSelect={this.handleCheckboxSelect}
              handleUnassociateClick={this.handleUnassociateClick}
            />
          }
          associationLoading={this.state.associationLoading}
        />

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />
      </Fragment>
    )
  }
}
