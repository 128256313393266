import React, { useState } from "react"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core"
import Box from "../Box"
import { useForm, Controller } from 'react-hook-form'
import Alert from "@material-ui/lab/Alert"

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  alert: {
    marginBottom: theme.spacing(2)
  }
}))

const AddUserModal = ({ open, onClose, onSubmit }) => {
  const classes = useStyles()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [serverError, setServerError] = useState(null)
  
  const { 
    control, 
    handleSubmit, 
    formState: { errors },
    reset
  } = useForm()

  const handleClose = () => {
    reset()
    setServerError(null)
    onClose()
  }

  const onFormSubmit = async (data) => {
    try {
      setIsSubmitting(true)
      setServerError(null)

      await onSubmit({
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        department: data.department,
        role: data.role
      })
    } catch (error) {
      setServerError(error.responseJSON?.error || 'An error occurred while adding the user')
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Dialog 
      open={open} 
      onClose={handleClose} 
      maxWidth="sm" 
      fullWidth
    >
      <DialogTitle>Add New User</DialogTitle>
      <DialogContent>
        <Box p={2}>
          {serverError && (
            <Alert severity="error" className={classes.alert}>
              {serverError}
            </Alert>
          )}

          <form className={classes.form} onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="firstName"
                  control={control}
                  rules={{ required: 'First name is required' }}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      autoFocus
                      variant="outlined"
                      fullWidth
                      label="First Name"
                      error={!!errors.firstName}
                      helperText={errors.firstName?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  name="lastName"
                  control={control}
                  rules={{ required: 'Last name is required' }}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      fullWidth
                      label="Last Name"
                      error={!!errors.lastName}
                      helperText={errors.lastName?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  rules={{ 
                    required: 'Email is required',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Invalid email address'
                    }
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      fullWidth
                      label="Email"
                      error={!!errors.email}
                      helperText={errors.email?.message || "An invitation email will be sent to this address"}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  name="department"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      fullWidth
                      label="Department"
                      error={!!errors.department}
                      helperText={errors.department?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  name="role"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      fullWidth
                      label="Role"
                      error={!!errors.role}
                      helperText={errors.role?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button 
                type="submit"
                color="primary" 
                variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Sending Invitation...' : 'Send Invitation'}
              </Button>
            </DialogActions>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default AddUserModal 