import React, { useState, useEffect } from "react"
import Box from "../Box"
import Paper from "../Paper"
import Grid from '@material-ui/core/Grid'
import Typography from "@material-ui/core/Typography"
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import GetAppIcon from '@material-ui/icons/GetApp'
import { format } from 'date-fns'
import SearchableSelect from '../SearchableSelect'
import { FormControlLabel, Switch } from '@material-ui/core'

const UserUsage = ({ users, usage_data, company_sectors }) => {
  const [usageData, setUsageData] = useState(usage_data)
  const [sectorNames, setSectorNames] = useState(company_sectors.map(sector => sector.abbr))
  const [startDate, setStartDate] = useState(format(new Date().setDate(new Date().getDate() - 30), 'yyyy-MM-dd'))
  const [endDate, setEndDate] = useState(format(new Date(), 'yyyy-MM-dd'))
  const [selectedUsers, setSelectedUsers] = useState([])
  const [orderBy, setOrderBy] = useState('total_clicks')
  const [order, setOrder] = useState('desc')
  const [showAllUsers, setShowAllUsers] = useState(false)

  const userOptions = users.map(user => ({
    name: `${user.last_name}, ${user.first_name}`,
    value: user.id
  }))

  const fetchUsageData = () => {
    const params = {
      start_date: startDate,
      end_date: endDate,
      show_all: showAllUsers,
      ...(selectedUsers.length > 0 && { user_ids: selectedUsers.join(',') })
    }

    $.ajax({
      url: '/user_management/usage',
      type: 'GET',
      data: params,
      dataType: 'json',
      success: (data) => {
        setUsageData(data.usage_data)
        setSectorNames(data.sectors)
      }
    })
  }

  useEffect(() => {
    fetchUsageData()
  }, [showAllUsers])

  const handleUserChange = (name, values) => {
    setSelectedUsers(values || [])
  }

  const handleDownload = () => {
    const params = {
      start_date: startDate,
      end_date: endDate,
      show_all: showAllUsers,
      ...(selectedUsers.length > 0 && { user_ids: selectedUsers.join(',') })
    }
    
    window.location.href = `/user_management/usage.csv?${$.param(params)}`
  }

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const sortData = (data) => {
    return data.sort((a, b) => {
      let valueA = a[orderBy]
      let valueB = b[orderBy]

      if (orderBy === 'total_clicks') {
        valueA = a.sectors.reduce((sum, sector) => sum + sector.clicks, 0) + a.alerts
        valueB = b.sectors.reduce((sum, sector) => sum + sector.clicks, 0) + b.alerts
      } else if (orderBy.startsWith('sectors.')) {
        const sector = orderBy.split('.')[1]
        valueA = a.sectors.find(s => s.sector_abbr === sector)?.clicks || 0
        valueB = b.sectors.find(s => s.sector_abbr === sector)?.clicks || 0
      }

      if (order === 'desc') {
        [valueA, valueB] = [valueB, valueA]
      }

      if (typeof valueA === 'string') {
        return valueA.localeCompare(valueB)
      }

      return valueA - valueB
    })
  }

  const renderSortLabel = (property, label) => (
    <TableSortLabel
      active={orderBy === property}
      direction={orderBy === property ? order : 'asc'}
      onClick={() => handleSort(property)}
    >
      {label}
    </TableSortLabel>
  )

  return (
    <Box p={3}>
      <Paper>
        <Box p={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                type="date"
                label="Start Date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                type="date"
                label="End Date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            
            <Grid item xs={12} sm={4}>
              <SearchableSelect
                label="Users"
                name="users"
                value={selectedUsers}
                entities={userOptions}
                handleChange={handleUserChange}
                multiple={true}
                includeBlank={true}
              />
            </Grid>

            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end" alignItems="center" mb={2}>
                {/* <FormControlLabel
                  control={
                    <Switch
                      checked={showAllUsers}
                      onChange={(e) => setShowAllUsers(e.target.checked)}
                      color="primary"
                    />
                  }
                  label="Show All Users (Including No Activity)"
                /> */}
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={fetchUsageData}
                    style={{ marginRight: 8 }}
                  >
                    Apply Filters
                  </Button>
                  
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<GetAppIcon />}
                    onClick={handleDownload}
                  >
                    Download CSV
                  </Button>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{renderSortLabel('last_name', 'Last Name')}</TableCell>
                      <TableCell>{renderSortLabel('first_name', 'First Name')}</TableCell>
                      {sectorNames.map(sector => (
                        <TableCell key={sector}>
                          {renderSortLabel(`sectors.${sector}`, sector)}
                        </TableCell>
                      ))}
                      <TableCell>{renderSortLabel('alerts', 'Alerts')}</TableCell>
                      <TableCell>{renderSortLabel('total_clicks', 'Total Clicks')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortData([...usageData]).map((user) => {
                      const totalClicks = user.sectors.reduce((sum, sector) => sum + sector.clicks, 0) + user.alerts
                      return (
                        <TableRow key={user.id}>
                          <TableCell>{user.last_name}</TableCell>
                          <TableCell>{user.first_name}</TableCell>
                          {sectorNames.map(sector => (
                            <TableCell key={sector}>
                              {user.sectors.find(s => s.sector_abbr === sector)?.clicks || 0}
                            </TableCell>
                          ))}
                          <TableCell>{user.alerts}</TableCell>
                          <TableCell>{totalClicks}</TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  )
}

export default UserUsage