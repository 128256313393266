import React, { forwardRef } from 'react'
import { TableCell, TableRow } from '../../tables'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Chip from '@material-ui/core/Chip'
import Tooltip from '@material-ui/core/Tooltip'
import Box from '../../Box'
import Link from "../../Link"

const PatentReviewRow = forwardRef(({ patent, status, onMarkNotRelevant, onMarkReviewed }, ref) => {
  const getStatusColor = () => {
    switch (status) {
      case 'Reviewed':
        return 'success'
      case 'Automated':
        return 'info'
      case 'Needs Work':
      case 'Needs Sector':
        return 'warning'
      case 'Not Relevant':
        return 'error'
      default:
        return 'default'
    }
  }

  const handleNotRelevantClick = () => {
    if (window.confirm('Are you sure you want to mark this patent as not relevant?')) {
      onMarkNotRelevant(patent)
    }
  }

  const handleReviewClick = () => {
    if (status === 'Reviewed') {
      if (window.confirm('Are you sure you want to remove the review status from this patent?')) {
        onMarkReviewed(patent, false)
      }
    } else {
      if (window.confirm('Are you sure you want to mark this patent as reviewed?')) {
        onMarkReviewed(patent, true)
      }
    }
  }

  const getSectorTooltip = (sector, isPredicted) => {
    if (isPredicted) {
      return `Predicted sector: ${sector.name}. This sector was suggested by our prediction system but hasn't been verified yet.`
    }
    const addedBy = sector.created_by === 1 ? 'automatically' : 'manually'
    return `Assigned sector: ${sector.name}. This sector was ${addedBy} assigned to this patent.`
  }

  const renderSectors = () => {
    const sectors = patent.patent_sectors?.filter(ps => !ps.deleted).map(ps => ps.sector) || []
    
    const predictions = patent.patent_sector_predictions?.filter(psp => !psp.deleted).map(psp => ({
      ...psp.sector,
      predicted: true
    })) || []
    
    const allSectors = [...sectors, ...predictions.filter(p => 
      !sectors.some(s => s.id === p.id)
    )]

    return (
      <Box display="flex" flexWrap="wrap" gap={1}>
        {allSectors.map(sector => (
          <Tooltip 
            key={`${sector.id}-${sector.predicted ? 'pred' : 'actual'}`}
            title={getSectorTooltip(sector, sector.predicted)}
            arrow
          >
            <Chip
              label={sector.name}
              size="small"
              variant={sector.predicted ? "outlined" : "filled"}
              color={sector.predicted ? "default" : "primary"}
            />
          </Tooltip>
        ))}
      </Box>
    )
  }

  return (
    <TableRow ref={ref}>
      <TableCell>
        <Link href={`/admin/patents/${patent.id}/associator`} target="_blank" textColor="mmi-orange">
          {patent.html_patent_no}
        </Link>
      </TableCell>
      <TableCell>{patent.title}</TableCell>
      <TableCell>
        {patent.patent_company?.company?.short_name || 
         <Chip label="No Company" size="small" variant="outlined" />}
      </TableCell>
      <TableCell>{renderSectors()}</TableCell>
      <TableCell>
        <Tooltip 
          title={getStatusTooltip(status)}
          arrow
        >
          <Chip
            label={status}
            size="small"
            color={getStatusColor()}
          />
        </Tooltip>
      </TableCell>
      <TableCell>
        <Box display="flex" gap={1} alignItems="center">
          <Tooltip title={status === 'Reviewed' ? 'Remove review status' : 'Mark this patent as reviewed and complete'} arrow>
            <Button
              variant={status === 'Reviewed' ? 'contained' : 'outlined'}
              size="small"
              color={status === 'Reviewed' ? 'primary' : 'default'}
              onClick={handleReviewClick}
            >
              {status === 'Reviewed' ? 'Mark Incomplete' : 'Mark Complete'}
            </Button>
          </Tooltip>
          <Tooltip 
            title="Mark this patent as not relevant to any monitored sectors. This will remove it from the review queue."
            arrow
          >
            <IconButton
              size="small"
              onClick={handleNotRelevantClick}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </TableCell>
    </TableRow>
  )
})

const getStatusTooltip = (status) => {
  switch (status) {
    case 'Completed':
      return 'This patent has been manually reviewed and classified with both company and sector assignments'
    case 'Automated':
      return 'This patent has been automatically classified and needs verification'
    case 'Needs Work':
      return 'This patent needs both company and sector assignments'
    case 'Needs Sector':
      return 'This patent has a company assignment but needs sector classification'
    case 'Not Relevant':
      return 'This patent has been marked as not relevant to any monitored sectors'
    default:
      return 'Unknown status'
  }
}

export default PatentReviewRow 