import React, { useState } from "react"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import Collapse from "@material-ui/core/Collapse"
import Switch from "@material-ui/core/Switch"
import IconButton from "@material-ui/core/IconButton"
import ExpandMore from "@material-ui/icons/ExpandMore"
import ExpandLess from "@material-ui/icons/ExpandLess"
import Button from "@material-ui/core/Button"
import AddIcon from "@material-ui/icons/Add"
import Box from "../Box"
import Paper from "../Paper"
import Typography from "@material-ui/core/Typography"
import AddUserModal from "./AddUserModal"
import { withSnackbar } from "../SnackbarContainer"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Chip from "@material-ui/core/Chip"

const UserList = ({ users: initialUsers, snackbarShowMessage, user_company_sectors }) => {
  const [users, setUsers] = useState(initialUsers)
  const [expandedUser, setExpandedUser] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [showInactive, setShowInactive] = useState(false)
  
  const fetchUsers = () => {
    $.ajax({
      url: '/users',
      type: 'GET',
      dataType: 'json',
      data: { show_inactive: showInactive },
      success: (response) => {
        if (response && response.users) {
          setUsers(response.users)
        } else {
          console.error('Invalid response format:', response)
          snackbarShowMessage('Failed to fetch users: Invalid response format', 'error')
        }
      },
      error: (error) => {
        console.error('Error fetching users:', error)
        snackbarShowMessage('Failed to fetch users', 'error')
      }
    })
  }

  const handleShowInactiveToggle = (e) => {
    const newValue = e.target.checked
    setShowInactive(newValue)
    $.ajax({
      url: '/users',
      type: 'GET',
      dataType: 'json',
      data: { show_inactive: newValue },
      success: (response) => {
        if (response && response.users) {
          setUsers(response.users)
        } else {
          console.error('Invalid response format:', response)
          snackbarShowMessage('Failed to fetch users: Invalid response format', 'error')
        }
      },
      error: (error) => {
        console.error('Error fetching users:', error)
        snackbarShowMessage('Failed to fetch users', 'error')
      }
    })
  }

  const handleUserAccess = (userId) => {
    const updatedUser = users.find(u => u.id === userId)
    const newActiveState = !updatedUser.active

    $.ajax({
      url: `/users/${userId}`,
      type: 'PUT',
      data: { user: { active: newActiveState } },
      success: (response) => {
        setUsers(users.map(user => 
          user.id === userId ? { ...user, active: newActiveState } : user
        ))
        snackbarShowMessage(`User ${newActiveState ? 'activated' : 'deactivated'} successfully`)
        fetchUsers() // Refresh the list to get updated status
      },
      error: (error) => {
        console.error('Error updating user access:', error)
        snackbarShowMessage('Failed to update user access', 'error')
      }
    })
  }

  const handleSectorAccess = (userSectorId) => {
    const userSector = users
      .flatMap(user => user.user_sectors)
      .find(us => us.id === userSectorId)
    const newDeletedState = !userSector.deleted

    $.ajax({
      url: `/user_sectors/${userSectorId}`,
      type: 'PUT',
      data: { user_sector: { deleted: newDeletedState } },
      success: (response) => {
        setUsers(users.map(user => ({
          ...user,
          user_sectors: user.user_sectors.map(us =>
            us.id === userSectorId ? { ...us, deleted: newDeletedState } : us
          )
        })))
        snackbarShowMessage(`Sector access ${newDeletedState ? 'removed' : 'granted'} successfully`)
      },
      error: (error) => {
        console.error('Error updating sector access:', error)
        snackbarShowMessage('Failed to update sector access', 'error')
      }
    })
  }

  const handleInviteUser = (userData) => {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: '/users/invite',
        type: 'POST',
        data: { 
          email: userData.email,
          first_name: userData.firstName,
          last_name: userData.lastName,
          department: userData.department,
          role: userData.role
        },
        success: (response) => {
          setIsModalOpen(false)
          fetchUsers() // Refresh the list after adding a new user
          resolve(response)
        },
        error: (error) => {
          console.error('Error inviting user:', error)
          reject(error)
        }
      })
    })
  }

  const getStatusChipProps = (user) => {
    if (!user.active) {
      return { 
        label: 'Inactive', 
        style: { backgroundColor: '#9e9e9e', color: 'white' }  // Gray
      }
    }
    if (user.expired) {
      return { 
        label: 'Expired', 
        style: { backgroundColor: '#f44336', color: 'white' }  // Red
      }
    }
    return { 
      label: 'Active', 
      style: { backgroundColor: '#4caf50', color: 'white' }  // Green
    }
  }

  return (
    <Box p={3}>
      <Paper>
        <Box p={3}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={showInactive}
                  onChange={handleShowInactiveToggle}
                  color="primary"
                />
              }
              label="Show Inactive Users"
            />
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => setIsModalOpen(true)}
            >
              Add User
            </Button>
          </Box>

          <List>
            {users.map((user) => (
              <React.Fragment key={user.id}>
                <ListItem divider button onClick={() => setExpandedUser(expandedUser === user.id ? null : user.id)}>
                  <ListItemText 
                    primary={
                      <Box display="flex" alignItems="center">
                        <span style={{ marginRight: 8 }}>{`${user.first_name} ${user.last_name}`}</span>
                        <Chip 
                          size="small"
                          {...getStatusChipProps(user)}
                        />
                      </Box>
                    }
                    secondary={
                      <Box>
                        <div>{user.email}</div>
                        {user.department && <div>Department: {user.department}</div>}
                        {user.role && <div>Role: {user.role}</div>}
                      </Box>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Box display="flex" alignItems="center">
                      <Switch
                        edge="end"
                        onChange={() => handleUserAccess(user.id)}
                        checked={user.active}
                      />
                      <IconButton 
                        edge="end" 
                        onClick={(e) => {
                          e.stopPropagation();
                          setExpandedUser(expandedUser === user.id ? null : user.id)
                        }}
                        style={{ marginLeft: 8 }}
                      >
                        {expandedUser === user.id ? <ExpandLess /> : <ExpandMore />}
                      </IconButton>
                    </Box>
                  </ListItemSecondaryAction>
                </ListItem>

                <Collapse in={expandedUser === user.id} timeout="auto" unmountOnExit>
                  <Box pl={4} pr={2} pb={2}>
                    <Typography variant="subtitle2" gutterBottom>
                      Sectors
                    </Typography>
                    <List dense>
                      {user.sectors
                        .filter(sector => user_company_sectors.some(sector => sector.id === sector.id))
                        .map((sector) => {
                        const userSector = user.user_sectors.find(us => us.sector_id === sector.id)
                        if (!userSector) return null
                        return (
                          <ListItem divider key={sector.id}>
                            <ListItemText 
                              primary={sector.name}
                              secondary={sector.abbr}
                            />
                            <ListItemSecondaryAction>
                              <Switch
                                edge="end"
                                onChange={() => handleSectorAccess(userSector.id)}
                                checked={!userSector.deleted}
                              />
                            </ListItemSecondaryAction>
                          </ListItem>
                        )
                      })}
                    </List>
                  </Box>
                </Collapse>
              </React.Fragment>
            ))}
          </List>

          <AddUserModal
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            onSubmit={handleInviteUser}
          />
        </Box>
      </Paper>
    </Box>
  )
}

export default withSnackbar(UserList)
