import React, { useState } from "react"
import { makeStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import Divider from '@material-ui/core/Divider'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import FormControl from '@material-ui/core/FormControl'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 120,
    width: '100%'
  },
  selectAll: {
    padding: '4px 12px',
    borderBottom: `1px solid ${theme.palette.divider}`,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[8],
    borderRadius: theme.shape.borderRadius,
    maxHeight: '450px',
    overflow: 'auto'
  },
  option: {
    padding: '2px 8px',
    whiteSpace: 'normal',
    minHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.875rem',
    '& .MuiCheckbox-root': {
      padding: '2px',
      marginRight: '4px'
    }
  },
  specialOptionsGroup: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginBottom: '4px'
  },
  popper: {
    width: 'auto !important',
    zIndex: 1300
  },
  inputRoot: {
    width: '100%'
  },
  listbox: {
    padding: '2px 0',
    maxHeight: '450px',
    overflow: 'auto'
  },
  list: {
    padding: 0,
    margin: 0,
    listStyle: 'none'
  }
}))

const CustomPopper = function(props) {
  const classes = useStyles()
  return (
    <Popper
      {...props}
      placement="bottom-start"
      className={classes.popper}
      modifiers={{
        preventOverflow: {
          enabled: true,
          boundariesElement: 'viewport'
        },
        flip: {
          enabled: true
        },
        sameWidth: {
          enabled: true
        }
      }}
    />
  )
}

export default function SearchableSelect(props) {
  const classes = useStyles()
  const { label, name, value, entities, handleChange, multiple, includeBlank, disabled } = props
  const [open, setOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [selectedOptions, setSelectedOptions] = useState([]);
  
  const options = React.useMemo(() => {
    // Always create None and Select All options if includeBlank is true
    const specialOptions = includeBlank 
      ? [
          { name: 'None', value: null },
          { name: 'Select All', value: '__select_all__' }
        ]
      : [];
    
    return [...specialOptions, ...entities];
  }, [entities, includeBlank]);

  // Sync selectedOptions when props.value changes
  React.useEffect(() => {
    const newSelectedOptions = multiple
      ? options.filter((option) => value.includes(option.value))
      : options.find((option) => option.value === value) || null;

    setSelectedOptions(newSelectedOptions);
  }, [value, options, multiple]);

  const handleAutocompleteChange = (event, newValue, reason) => {
    if (handleChange && multiple) {
      if (newValue.some((item) => item.value === '__select_all__')) {
        const filteredOptions = options.filter(option => {
          if (option.value === '__select_all__' || option.value === null) return false
          return option.name.toLowerCase().includes(inputValue.toLowerCase())
        })
        handleChange(name, filteredOptions.map((option) => option.value));
      } else {
        handleChange(name, newValue.map((item) => item.value));
      }
    } else if (handleChange) {
      handleChange(name, newValue ? newValue.value : null);
    }
  };

  return (
    <FormControl margin='dense' size='small' variant='outlined' className={classes.formControl}>
      <Autocomplete
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        multiple={multiple}
        options={options}
        value={selectedOptions}
        disableCloseOnSelect={true}
        getOptionLabel={(option) => option.name}
        onChange={handleAutocompleteChange}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
        disabled={disabled}
        size="small"
        classes={{
          inputRoot: classes.inputRoot,
          option: classes.option,
          paper: classes.paper
        }}
        renderTags={() => null}
        clearIcon={false}
        disableClearable={true}
        ListboxProps={{
          className: classes.listbox
        }}
        PopperComponent={CustomPopper}
        filterOptions={(options, { inputValue }) => {
          // Get special options
          const noneOption = options.find(option => option.value === null);
          const selectAllOption = options.find(option => option.value === '__select_all__');
          
          // Filter regular options
          const filteredOptions = options.filter(option => {
            if (option.value === '__select_all__' || option.value === null) return false
            return option.name.toLowerCase().includes(inputValue.toLowerCase())
          })

          // Add group property to special options
          const specialOptions = [];
          if (noneOption) specialOptions.push({ ...noneOption, group: 'special' });
          if (selectAllOption) specialOptions.push({ ...selectAllOption, group: 'special' });

          // Return special options followed by regular options
          return [...specialOptions, ...filteredOptions];
        }}
        renderOption={(option, { selected }) => {
          // Don't show checkbox for "None" option in multiple select
          const showCheckbox = multiple && (!includeBlank || option.value !== null)
          const isSpecialOption = option.value === null || option.value === '__select_all__'
          
          return (
            <div className={`${classes.option} ${isSpecialOption ? classes.specialOption : ''}`}>
              {showCheckbox && (
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  checked={selected}
                  size="small"
                />
              )}
              {option.name}
            </div>
          )
        }}
        ListboxComponent={React.forwardRef((props, ref) => {
          const { children, ...other } = props;
          const classes = useStyles();
          return (
            <ul {...other} ref={ref} className={classes.list}>
              <div className={classes.specialOptionsGroup}>
                {React.Children.toArray(children).slice(0, 2)}
              </div>
              <div>
                {React.Children.toArray(children).slice(2)}
              </div>
            </ul>
          );
        })}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={label}
            margin="dense"
          />
        )}
      />
    </FormControl>
  )
} 