import React from "react"
import Flex from "../Flex"
import Box from "../Box"
import SearchIcon from '@material-ui/icons/Search'
import TextInput from "../TextInput"
import InputAdornment from '@material-ui/core/InputAdornment';
import RaisedButton from "../buttons/RaisedButton"
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import Autocomplete from "./Autocomplete"
import Tips from "./Tips"
import InfoIcon from '@material-ui/icons/Info';

export default function Form(props) {

  const handleSuggestionSelect = (value) => {
    props.handleSuggestionSelect(value);
  };

  return(
    <form onSubmit={props.handleSearchClick} acceptCharset="UTF-8">
      <Flex alignItems='center' width={1} justifyContent='center'>
        <Box width={1/2}>
          <Flex alignItems='center'>
            <Box flex={1}>
              <TextInput
                name='query'
                label='Search'
                value={props.query}
                onChange={props.handleSearchChange}
                fullWidth
                placeholder={props.placeholderText}
                InputProps={{
                  startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton 
                        onClick={(e) => {
                          e.preventDefault();
                          if (props.handleClearSearchClick) {
                            props.handleClearSearchClick(e);
                          }
                        }} 
                        size='small'
                      >
                        <ClearIcon fontSize='small' />
                      </IconButton>
                      <IconButton 
                        onClick={props.handleTipsClick} 
                        color="default" 
                        size='small'
                      >
                        <InfoIcon fontSize='small' />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                helperText={props.helperText}
              />
              <Autocomplete
                suggestions={props.suggestions}
                onSelect={handleSuggestionSelect}
                show={props.showSuggestions}
                setShowSuggestions={props.setShowSuggestions}
              />
            </Box>
          </Flex>
        </Box>

        <Box ml={3}>
          <RaisedButton onClick={props.handleSearchClick}>
            Search
          </RaisedButton>
        </Box>
      </Flex>
    </form>
  )
}