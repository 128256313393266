import React, { Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Flex from "../Flex"
import Box from "../Box"
import FilterChip from '../filters/FilterChip';

export default function FilterChipsContainer(props) {
  let collectionOptionValues = props.filterOptions.collections.map(collection => collection.value)
  const selectedCollections = props.chosen.collections.filter(collection => props.filterValues.collection_ids.includes(collection.id) ? collection : null)

  let companyOptionValues = props.filterOptions.companies.map(company => company.value)
  const selectedCompanies = props.chosen.companies.filter(company => props.filterValues.company_ids.includes(company.id) ? company : null)

  let targetSiteOptionValues = props.filterOptions.targetSites.map(targetSite => targetSite.value)
  const selectedTargetSites = props.chosen.targetSites.filter(targetSite => props.filterValues.target_site_ids.includes(targetSite.id) ? targetSite : null)

  let targetMarketOptionValues = props.filterOptions.targetMarkets.map(targetMarketOption => targetMarketOption.value)
  const selectedTargetMarkets = props.chosen.targetMarkets.filter(targetMarket => props.filterValues.target_market_ids.includes(targetMarket.id) ? targetMarket : null)

  let therapyTypeOptionValues = props.filterOptions.therapyTypes.map(therapyTypeOption => therapyTypeOption.value)
  const selectedTherapyTypes = props.chosen.therapyTypes.filter(therapyType => props.filterValues.therapy_type_ids.includes(therapyType.id) ? therapyType : null)

  let stentConfigurationOptionValues = props.filterOptions.stentConfigurations.map(stentConfigurationOption => stentConfigurationOption.value)
  const selectedStentConfigurations = props.chosen.stentConfigurations.filter(stentConfiguration => props.filterValues.stent_configuration_ids.includes(stentConfiguration.id) ? stentConfiguration : null)

  let methodOptionValues = props.filterOptions.methods ? props.filterOptions.methods.map(methodOption => methodOption.value) : null
  const selectedMethods = props.filterOptions.methods ? props.chosen.methods.filter(method => props.filterValues.method_type_ids.includes(method.id) ? method : null) : []

  let polymerTypeOptionValues = props.filterOptions.polymerTypes ? props.filterOptions.polymerTypes.map(polymerType => polymerType.value) : null
  const selectedPolymerTypes = props.filterOptions.polymerTypes ? props.chosen.polymerTypes.filter(polymerType => props.filterValues.polymer_type_ids.includes(polymerType.id) ? polymerType : null) : []

  let regulatoryStatusOptionValues = props.filterOptions.regulatoryStatuses.map(regulatoryStatus => regulatoryStatus.value)
  const selectedRegulatoryStatuses = []
  if (props.filterValues.regulatory_status_ids.includes('approved')) {
    selectedRegulatoryStatuses.push({ id: 'approved', name: 'Approved' })
  }
  const otherStatuses = props.chosen.regulatoryStatuses.filter(regulatoryStatus => 
    props.filterValues.regulatory_status_ids.includes(regulatoryStatus.id)
  )
  selectedRegulatoryStatuses.push(...otherStatuses)
  
  let selectedCharacteristicValues = props.characteristicValueFilter ? props.chosen.characteristicValues.filter(characteristicValue => props.filterValues.characteristic_value_ids.includes(characteristicValue.id) ? characteristicValue : null) : []

  let characteristicValueOptions = props.characteristicValueFilter ? Object.values(props.filterOptions.productCharacteristics).flat().map(productCharacteristic => productCharacteristic.id) : []

  let technologyGroupOptionValues = props.filterOptions.technologyGroups ? props.filterOptions.technologyGroups.map(technologyGroup => technologyGroup.value) : null
  const selectedTechnologyGroups = props.filterOptions.technologyGroups ? (props.chosen.technologyGroups ? props.chosen.technologyGroups.filter(technologyGroup => props.filterValues.technology_group_ids.includes(technologyGroup.id) ? technologyGroup : null) : []) : []

  return(
    <Fragment>

      {selectedCollections.map((activeFilter) =>
        <FilterChip
          key={activeFilter.id}
          name={activeFilter.title}
          value={activeFilter.id}
          className={collectionOptionValues.includes(activeFilter.id) ? '' : 'o-60'}
          type='collection_ids'
          handleDelete={props.handleDelete}
        />
      )}
      {selectedCompanies.map((activeFilter) =>
        <FilterChip
          key={activeFilter.id}
          name={activeFilter.short_name}
          value={activeFilter.id}
          className={companyOptionValues.includes(activeFilter.id) ? '' : 'o-60'}
          type='company_ids'
          handleDelete={props.handleDelete}
        />
      )}
      {selectedTargetSites.map((activeFilter) =>
        <FilterChip
          key={activeFilter.value}
          name={activeFilter.name}
          value={activeFilter.id}
          className={targetSiteOptionValues.includes(activeFilter.id) ? '' : 'o-60'}
          type='target_site_ids'
          handleDelete={props.handleDelete}
        />
      )}
      {selectedTargetMarkets.map((activeFilter) =>
        <FilterChip
          key={`${activeFilter.value} - target_market_ids`}
          className={targetMarketOptionValues.includes(activeFilter.id) ? '' : 'o-60'}
          name={activeFilter.name}
          value={activeFilter.id}
          type='target_market_ids'
          handleDelete={props.handleDelete}
        />
      )}
      {selectedTherapyTypes.map((activeFilter) =>
        <FilterChip
          key={activeFilter.id}
          className={therapyTypeOptionValues.includes(activeFilter.id) ? '' : 'o-60'}
          name={activeFilter.name}
          value={activeFilter.id}
          type='therapy_type_ids'
          handleDelete={props.handleDelete}
        />
      )}
      {selectedStentConfigurations.map((activeFilter) =>
        <FilterChip
          key={activeFilter.id}
          className={stentConfigurationOptionValues.includes(activeFilter.id) ? '' : 'o-60'}
          name={activeFilter.name}
          value={activeFilter.id}
          type='stent_configuration_ids'
          handleDelete={props.handleDelete}
        />
      )}
      {selectedMethods.map((activeFilter) =>
        <FilterChip
          key={activeFilter.value}
          className={methodOptionValues.includes(activeFilter.id) ? '' : 'o-60'}
          name={activeFilter.name}
          value={activeFilter.id}
          type='method_type_ids'
          handleDelete={props.handleDelete}
        />
      )}
      {selectedPolymerTypes.map((activeFilter) =>
        <FilterChip
          key={activeFilter.value}
          className={polymerTypeOptionValues.includes(activeFilter.id) ? '' : 'o-60'}
          name={activeFilter.name}
          value={activeFilter.id}
          type='polymer_type_ids'
          handleDelete={props.handleDelete}
        />
      )}
      {selectedRegulatoryStatuses.map((activeFilter) =>
        <FilterChip
          key={activeFilter.value}
          name={activeFilter.name}
          value={activeFilter.id}
          className={regulatoryStatusOptionValues.includes(activeFilter.id) ? '' : 'o-60'}
          type='regulatory_status_ids'
          handleDelete={props.handleDelete}
        />
      )}
      {selectedTechnologyGroups.map((activeFilter) =>
        <FilterChip
          key={`${activeFilter.value} - technology_group_ids`}
          className={technologyGroupOptionValues.includes(activeFilter.id) ? '' : 'o-60'}
          name={activeFilter.name}
          value={activeFilter.id}
          type='technology_group_ids'
          handleDelete={props.handleDelete}
        />
      )}
      {selectedCharacteristicValues.map((characteristicValue) =>
        <FilterChip
          key={characteristicValue.id}
          name={characteristicValue.name}
          value={characteristicValue.id}
          className={characteristicValueOptions.includes(characteristicValue.id) ? '' : 'o-60'}
          type='characteristic_value_ids'
          handleDelete={props.handleDelete}
        />
      )}
    </Fragment>
  )
}
