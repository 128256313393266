import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import SearchableSelect from "../SearchableSelect"
import Flex from "../Flex"
import Grid from '@material-ui/core/Grid';
import OutlinedButton from "../buttons/OutlinedButton"

export default class Filters extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const selectedCollections = this.props.filterOptions.collections.filter(collection => this.props.filterValues.collection_ids.includes(collection.value) ? collection : null)
    const selectedTargetMarkets = this.props.filterOptions.targetMarkets.filter(targetMarket => this.props.filterValues.target_market_ids.includes(targetMarket.value) ? targetMarket : null)
    const selectedCompanies = this.props.filterOptions.companies.filter(company => this.props.filterValues.company_ids.includes(company.value) ? company : null)
    const selectedTargetSites = this.props.filterOptions.targetSites.filter(targetSite => this.props.filterValues.target_site_ids.includes(targetSite.value) ? targetSite : null)
    const selectedTherapyTypes = this.props.filterOptions.therapyTypes.filter(therapyType => this.props.filterValues.therapy_type_ids.includes(therapyType.value) ? therapyType : null)
    const selectedStentConfigurations = this.props.filterOptions.stentConfigurations ? this.props.filterOptions.stentConfigurations.filter(stentConfiguration => this.props.filterValues.stent_configuration_ids.includes(stentConfiguration.value) ? stentConfiguration : null) : []
    const selectedTechnologyGroups = this.props.filterOptions.technologyGroups ? this.props.filterOptions.technologyGroups.filter(technologyGroup => this.props.filterValues.technology_group_ids.includes(technologyGroup.value) ? technologyGroup : null) : []
    const selectedRegulatoryStatuses = this.props.filterOptions.regulatoryStatuses ? this.props.filterOptions.regulatoryStatuses.filter(regulatoryStatus => this.props.filterValues.regulatory_status_ids.includes(regulatoryStatus.value) ? regulatoryStatus : null) : []
    const selectedMethods = this.props.filterOptions.methods ? this.props.filterOptions.methods.filter(method => this.props.filterValues.method_type_ids.includes(method.value) ? method : null) : null
    const selectedPolymerTypes = this.props.filterOptions.polymerTypes ? this.props.filterOptions.polymerTypes.filter(polymerType => this.props.filterValues.polymer_type_ids.includes(polymerType.value) ? polymerType : null) : null

    return (
      <Grid container spacing={2} alignItems='center'>
        {this.props.filterOptions.countries && 
          <Grid item xs>
            <SearchableSelect
              label='Country/Region'
              name='country_ids'
              value={this.props.filterValues.country_ids}
              entities={this.props.filterOptions.countries}
              handleChange={this.props.handleFilterChange}
            />
          </Grid>
        }

        {this.props.filterOptions.collections && <Grid item xs>
          <SearchableSelect
            multiple
            label='Collections'
            name='collection_ids'
            value={this.props.filterValues.collection_ids}
            entities={this.props.filterOptions.collections}
            handleChange={this.props.handleFilterChange}
            includeBlank={true}
          />
        </Grid>}

        {this.props.filterOptions.companies && <Grid item xs>
          <SearchableSelect
            multiple
            label='Company'
            name='company_ids'
            value={this.props.filterValues.company_ids}
            entities={this.props.filterOptions.companies}
            handleChange={this.props.handleFilterChange}
            includeBlank={true}
          />
        </Grid>}

        {this.props.sector.monitor_product
        ?
          <Fragment>
            {this.props.sector.id == 27 && this.props.filterOptions.technologyGroups && this.props.filterOptions.technologyGroups.length > 0 && <Grid item xs>
              <SearchableSelect
                multiple
                label='Technology'
                name='technology_group_ids'
                value={this.props.filterValues.technology_group_ids}
                entities={this.props.filterOptions.technologyGroups}
                handleChange={this.props.handleFilterChange}
                includeBlank={true}
              />
            </Grid>}

            {this.props.filterOptions.regulatoryStatuses && <Grid item xs>
              <SearchableSelect
                multiple
                label='Status'
                name='regulatory_status_ids'
                value={this.props.filterValues.regulatory_status_ids}
                entities={this.props.filterOptions.regulatoryStatuses}
                handleChange={this.props.handleFilterChange}
                includeBlank={true}
              />
            </Grid>}
          </Fragment>
        :
          <Fragment>
            {this.props.filterOptions.regulatoryStatuses && <Grid item xs>
              <SearchableSelect
                multiple
                label='Status'
                name='regulatory_status_ids'
                value={this.props.filterValues.regulatory_status_ids}
                entities={this.props.filterOptions.regulatoryStatuses}
                handleChange={this.props.handleFilterChange}
                includeBlank={true}
              />
            </Grid>}

            {this.props.filterOptions.targetSites && this.props.filterOptions.targetSites.length > 0 &&
              <Grid item xs>
                <SearchableSelect
                  multiple
                  label='Target Sites'
                  name='target_site_ids'
                  value={this.props.filterValues.target_site_ids}
                  entities={this.props.filterOptions.targetSites}
                  handleChange={this.props.handleFilterChange}
                  includeBlank={true}
                />
              </Grid>
            }

            {this.props.filterOptions.targetMarkets && this.props.filterOptions.targetMarkets.length > 0 &&
              <Grid item xs>
                <SearchableSelect
                  multiple
                  label='Target Market'
                  name='target_market_ids'
                  value={this.props.filterValues.target_market_ids}
                  entities={this.props.filterOptions.targetMarkets}
                  handleChange={this.props.handleFilterChange}
                  includeBlank={true}
                />
              </Grid>
            }

            {this.props.filterOptions.stentConfigurations && this.props.filterOptions.stentConfigurations.length > 0 &&
              <Grid item xs>
                <SearchableSelect
                  multiple
                  label='Stent Configuration'
                  name='stent_configuration_ids'
                  value={this.props.filterValues.stent_configuration_ids}
                  entities={this.props.filterOptions.stentConfigurations}
                  handleChange={this.props.handleFilterChange}
                  includeBlank={true}
                />
              </Grid>
            }

            {this.props.filterOptions.therapyTypes && this.props.filterOptions.therapyTypes.length > 0 &&
              <Grid item xs>
                <SearchableSelect
                  multiple
                  label='Therapy'
                  name='therapy_type_ids'
                  value={this.props.filterValues.therapy_type_ids}
                  entities={this.props.filterOptions.therapyTypes}
                  handleChange={this.props.handleFilterChange}
                  includeBlank={true}
                />
              </Grid>
            }

            {this.props.filterOptions.methods &&
              <Grid item xs>
                <SearchableSelect
                  multiple
                  label='Methods'
                  name='method_type_ids'
                  value={this.props.filterValues.method_type_ids}
                  entities={this.props.filterOptions.methods}
                  handleChange={this.props.handleFilterChange}
                  includeBlank={true}
                />
              </Grid>
            }

            {this.props.filterOptions.polymerTypes &&
              <Grid item xs>
                <SearchableSelect
                  multiple
                  label='Polymer Types'
                  name='polymer_type_ids'
                  value={this.props.filterValues.polymer_type_ids}
                  entities={this.props.filterOptions.polymerTypes}
                  handleChange={this.props.handleFilterChange}
                  includeBlank={true}
                />
              </Grid>
            }
          </Fragment>
        }

        {this.props.filterValues.approved && 
          <Grid item xs>
            <SearchableSelect
              label='Status'
              name='approved'
              value={this.props.filterValues.approved}
              entities={[{ name: 'All', value: 'all' }, { name: 'Approved', value: 'approved' }, { name: 'Not approved', value: 'not_approved' }]}
              handleChange={this.props.handleFilterChange}
            />
          </Grid>
        }

        {this.props.filterOptions.productCharacteristics && Object.keys(this.props.filterOptions.productCharacteristics).length > 0 && <Grid item xs>
          <OutlinedButton color="default" onClick={this.props.handleMoreFilterClick}>
            More Filters
          </OutlinedButton>
        </Grid>}
      </Grid>
    )
  }
}
