import React, { useState } from 'react'
import Box from '../../Box'
import Paper from '../../Paper'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import { Table, TableCell, TableRow, TableBody, TableHead } from '../../tables'
import PatentReviewRow from './PatentReviewRow'
import { withSnackbar } from '../../SnackbarContainer'

const ReviewDashboard = ({ patents: initialPatents, date: initialDate, view_type: initialViewType, view_types, total_count: initialTotalCount, summary: initialSummary, currentUser, snackbarShowMessage }) => {
  const [patents, setPatents] = useState(initialPatents)
  const [date, setDate] = useState(initialDate)
  const [viewType, setViewType] = useState(initialViewType)
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [totalCount, setTotalCount] = useState(initialTotalCount || initialPatents?.length || 0)
  const [summary, setSummary] = useState(initialSummary || {})

  const handleDateChange = (event) => {
    const newDate = event.target.value
    setDate(newDate)
    setPage(0)
    setPatents([])
    fetchPatents(newDate, viewType, 0)
  }

  const handleViewTypeChange = (event) => {
    const newViewType = event.target.value
    setViewType(newViewType)
    setPage(0)
    setPatents([])
    fetchPatents(date, newViewType, 0)
  }

  const handlePageChange = (newPage) => {
    setPage(newPage)
    fetchPatents(date, viewType, newPage)
  }

  const fetchPatents = (selectedDate, selectedViewType, pageNum) => {
    setLoading(true)
    
    $.ajax({
      url: '/admin/patents/review_dashboard',
      type: 'GET',
      data: {
        date: selectedDate,
        view_type: selectedViewType,
        page: pageNum
      },
      dataType: 'JSON',
      success: (response) => {
        if (response && response.patents) {
          setTotalCount(response.total_count || 0)
          setPatents(response.patents)
          setSummary(response.summary || {})
        } else {
          setPatents([])
          setTotalCount(0)
          setSummary({})
        }
        setLoading(false)
      },
      error: (error) => {
        console.error('Error fetching patents:', error)
        setPatents([])
        setTotalCount(0)
        setSummary({})
        setLoading(false)
        alert('Failed to fetch patents. Please try again.')
      }
    })
  }

  const renderPagination = () => {
    const pageSize = 100
    const totalPages = Math.ceil(totalCount / pageSize)
    const currentPage = page
    const maxButtons = 5
    
    let startPage = Math.max(0, currentPage - Math.floor(maxButtons / 2))
    let endPage = Math.min(totalPages - 1, startPage + maxButtons - 1)
    
    if (endPage - startPage + 1 < maxButtons) {
      startPage = Math.max(0, endPage - maxButtons + 1)
    }

    const pages = []
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i)
    }

    return (
      <Box display="flex" alignItems="center" justifyContent="center" my={2}>
        <ButtonGroup size="small">
          <Button
            onClick={() => handlePageChange(0)}
            disabled={currentPage === 0 || loading}
          >
            First
          </Button>
          <Button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 0 || loading}
          >
            Previous
          </Button>
          {pages.map(p => (
            <Button
              key={p}
              onClick={() => handlePageChange(p)}
              variant={p === currentPage ? 'contained' : 'outlined'}
              color={p === currentPage ? 'primary' : 'default'}
              disabled={loading}
            >
              {p + 1}
            </Button>
          ))}
          <Button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage >= totalPages - 1 || loading}
          >
            Next
          </Button>
          <Button
            onClick={() => handlePageChange(totalPages - 1)}
            disabled={currentPage >= totalPages - 1 || loading}
          >
            Last
          </Button>
        </ButtonGroup>
      </Box>
    )
  }

  const getStatusLabel = (patent) => {
    if (!patent?.relevant) return 'Not Relevant'
    if (patent?.reviewed_at) return 'Reviewed'
    if (patent?.patent_sectors?.some(ps => ps.created_by === 1)) return 'Automated'
    if (!patent?.patent_company || !patent?.patent_sectors?.length) return 'Needs Work'
    if (patent?.patent_company && !patent?.patent_sectors?.length) return 'Needs Sector'
    return 'Unknown'
  }

  const handleMarkNotRelevant = (patent) => {
    if (!patent || !patent.id) return
    
    setLoading(true)
    $.ajax({
      type: 'PUT',
      url: `/admin/patents/${patent.id}`,
      data: {
        patent: {
          relevant: false
        }
      },
      dataType: 'json',
      success: (response) => {
        setPatents(currentPatents => {
          if (!Array.isArray(currentPatents)) return []
          return currentPatents.filter(p => p.id !== patent.id)
        })
        setLoading(false)
      },
      error: (error) => {
        console.error('Error marking patent as not relevant:', error)
        setLoading(false)
        alert('Failed to mark patent as not relevant. Please try again.')
      }
    })
  }

  const sortPatents = (patentsToSort) => {
    return [...patentsToSort].sort((a, b) => {
      // First, check reviewed status
      if (a.reviewed_at && !b.reviewed_at) return 1;
      if (!a.reviewed_at && b.reviewed_at) return -1;
      
      // If both are reviewed or both are not reviewed, maintain their relative order
      return 0;
    });
  };

  const handleMarkReviewed = (patent, reviewed) => {
    if (!patent || !patent.id) return
    
    setLoading(true)
    $.ajax({
      type: 'PUT',
      url: `/admin/patents/${patent.id}`,
      data: {
        patent: {
          reviewed_at: reviewed ? new Date().toISOString() : null,
          reviewed_by: reviewed ? currentUser.id : null
        }
      },
      dataType: 'json',
      success: (response) => {
        if (response && response.patent) {
          setPatents(currentPatents => {
            if (!Array.isArray(currentPatents)) return []
            // First update the patent's reviewed status
            const updatedPatents = currentPatents.map(p => 
              p.id === patent.id 
                ? { ...p, reviewed_at: response.patent.reviewed_at }
                : p
            );
            // Then sort the patents
            return sortPatents(updatedPatents);
          });
          
          // Show success message
          snackbarShowMessage(
            reviewed 
              ? `Patent ${patent.html_patent_no} marked as reviewed` 
              : `Review status removed from patent ${patent.html_patent_no}`,
            'success',
            3000
          );
        }
        setLoading(false)
      },
      error: (error) => {
        console.error('Error updating review status:', error)
        setLoading(false)
        snackbarShowMessage('Failed to update review status. Please try again.', 'error', 3000)
      }
    })
  }

  const renderSummary = () => {
    if (!summary || !summary.total) return null;

    const needsWork = summary.needs_work || 0;
    const automated = summary.automated || 0;
    const reviewed = summary.reviewed || 0;
    const total = summary.total || 0;
    const remaining = needsWork + automated;

    if (remaining === 0 && total > 0) {
      return (
        <Box mb={3} p={2} bgcolor="#e8f5e9" borderRadius={1} textAlign="center">
          <Typography variant="h6" color="success" style={{ fontWeight: 500 }}>
            ✓ All patents have been reviewed
          </Typography>
        </Box>
      );
    }

    return (
      <Box mb={3} p={2} bgcolor="#f5f5f5" borderRadius={1}>
        <Box display="flex" alignItems="center" gap={4} justifyContent="center">
          <Box textAlign="center">
            <Typography variant="h5" color="error" style={{ fontWeight: 500 }}>
              {remaining}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Need Review
            </Typography>
          </Box>

          <Box textAlign="center">
            <Typography variant="h5" color="success" style={{ fontWeight: 500 }}>
              {reviewed}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Reviewed
            </Typography>
          </Box>

          <Box textAlign="center">
            <Typography variant="h5" style={{ fontWeight: 500 }}>
              {total}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Total Patents
            </Typography>
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Paper>
      <Box p={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
          <Typography variant="h6">
            Patent Review Dashboard 
            {totalCount > 0 && <Typography component="span" color="textSecondary" style={{ marginLeft: 8 }}>
              ({page * 100 + 1}-{Math.min((page + 1) * 100, totalCount)} of {totalCount})
            </Typography>}
          </Typography>
          
          <Box display="flex" alignItems="center">
            <Box mr={2}>
              <TextField
                type="date"
                label="Date"
                value={date}
                onChange={handleDateChange}
                variant="outlined"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
            
            <FormControl variant="outlined" size="small">
              <InputLabel>View</InputLabel>
              <Select
                value={viewType}
                onChange={handleViewTypeChange}
                label="View"
              >
                {view_types.map(type => (
                  <MenuItem key={type.value} value={type.value}>
                    {type.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>

        {/* {renderSummary()} */}

        {renderPagination()}

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Patent #</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Company</TableCell>
              <TableCell>Sectors</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {patents.length === 0 && !loading ? (
              <TableRow>
                <TableCell colSpan={6} align="center">No patents found</TableCell>
              </TableRow>
            ) : (
              patents.map(patent => (
                <PatentReviewRow
                  key={patent.id}
                  patent={patent}
                  status={getStatusLabel(patent)}
                  onMarkNotRelevant={handleMarkNotRelevant}
                  onMarkReviewed={handleMarkReviewed}
                />
              ))
            )}
            {loading && (
              <TableRow>
                <TableCell colSpan={6} align="center">Loading patents...</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        {renderPagination()}
      </Box>
    </Paper>
  )
}

export default withSnackbar(ReviewDashboard) 