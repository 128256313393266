import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import { withSnackbar } from '../SnackbarContainer'

const CompanySelection = ({ companies, selectedCompanyId, snackbarShowMessage }) => {
  const [selectedCompany, setSelectedCompany] = useState(selectedCompanyId)

  const currentCompany = companies.find(c => c.id === selectedCompanyId)

  const handleCompanySelect = () => {
    if (selectedCompany === selectedCompanyId) {
      window.location.href = '/user_management/dashboard'
      return
    }

    $.ajax({
      url: '/user_management/set_company',
      type: 'POST',
      data: { user_company_id: selectedCompany },
      success: () => {
        window.location.href = '/user_management/dashboard'
      },
      error: (error) => {
        console.error('Error setting company:', error)
        snackbarShowMessage('Failed to set company', 'error')
      }
    })
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="center" minHeight="80vh">
      <Paper>
        <Box p={4} maxWidth={400}>
          <Typography variant="h5" gutterBottom align="center">
            Switch Company
          </Typography>
          
          {currentCompany && (
            <Typography variant="subtitle1" gutterBottom align="center" color="textSecondary">
              Currently managing: {currentCompany.name}
            </Typography>
          )}
          
          <Box mt={3}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="company-select-label">Select Company</InputLabel>
              <Select
                labelId="company-select-label"
                id="company-select"
                value={selectedCompany}
                onChange={(e) => setSelectedCompany(e.target.value)}
                label="Select Company"
              >
                {companies.map((company) => (
                  <MenuItem key={company.id} value={company.id}>
                    {company.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box mt={3} display="flex" justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              onClick={handleCompanySelect}
              disabled={!selectedCompany}
            >
              {selectedCompany === selectedCompanyId ? 'Return to Dashboard' : 'Switch Company'}
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  )
}

CompanySelection.propTypes = {
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  selectedCompanyId: PropTypes.number,
  snackbarShowMessage: PropTypes.func.isRequired
}

export default withSnackbar(CompanySelection) 