import React from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Box from "../../Box"
import Link from "../../Link"
import { Table, TableHead, TableRow, TableCell, TableBody, TableHeadSort } from "../../tables"
import { TextField, FormControlLabel, Switch, Select, MenuItem, InputLabel, FormControl } from "@material-ui/core"

function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export default class Users extends React.Component {
  constructor(props) {
    super(props)
    this.handleRequestSort = this.handleRequestSort.bind(this)
    this.desc = this.desc.bind(this)
    this.stableSort = this.stableSort.bind(this)
    this.getSorting = this.getSorting.bind(this)
    this.handleSearch = debounce(this.handleSearch.bind(this), 300)
    this.handleShowInactive = this.handleShowInactive.bind(this)
    this.handleCompanyFilter = this.handleCompanyFilter.bind(this)
    this.fetchUsers = this.fetchUsers.bind(this)

    this.state = { 
      users: this.props.users, 
      order: 'asc', 
      orderBy: 'expiration_date',
      query: '',
      showInactive: false,
      companyIds: [],
      companyOptions: this.props.companyOptions || []
    }
  }

  handleSearch(event) {
    const query = event.target.value
    this.setState({ query }, () => this.fetchUsers())
  }

  handleShowInactive(event) {
    this.setState({ showInactive: event.target.checked }, () => this.fetchUsers())
  }

  handleCompanyFilter(event) {
    this.setState({ companyIds: event.target.value }, () => this.fetchUsers())
  }

  fetchUsers() {
    const { query, showInactive, companyIds } = this.state
    fetch('/admin/users.json?' + new URLSearchParams({
      query,
      show_inactive: showInactive,
      company_ids: companyIds
    }))
    .then(response => response.json())
    .then(data => this.setState({ users: data.users, companyOptions: data.company_options }))
  }

  desc(a, b, orderBy) {
    let bOrderKey = orderBy == 'short_name' || orderBy == 'company_name' ? b[orderBy].toLowerCase() : b[orderBy]
    let aOrderKey = orderBy == 'short_name' || orderBy == 'company_name' ? a[orderBy].toLowerCase() : a[orderBy]
    if (bOrderKey < aOrderKey) {
      return -1;
    }
    if (bOrderKey > aOrderKey) {
      return 1;
    }
    return 0;
  }

  stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
  }

  handleRequestSort(event, property) {
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';

    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property
    })
  };

  render() {
    let headers = [{ label: 'Name', name: 'last_name' }, { label: 'Company', name: 'company_name' }, { label: 'Status', name: 'active' }, { label: 'Expiration date', name: 'expiration_date'} ]
    return(
      <React.Fragment>
        <Paper fullHeight>
          <Box height="100%">
            <Box mb={3} display="flex" alignItems="center" gap={2}>
              <TextField
                label="Search users"
                variant="outlined"
                size="small"
                onChange={this.handleSearch}
                style={{ minWidth: 200, marginRight: 16 }}
              />
              
              <FormControl variant="outlined" size="small" style={{ minWidth: 200, marginRight: 16 }}>
                <InputLabel>Filter by Company</InputLabel>
                <Select
                  multiple
                  value={this.state.companyIds}
                  onChange={this.handleCompanyFilter}
                  label="Filter by Company"
                >
                  {this.state.companyOptions.map(company => (
                    <MenuItem key={company.id} value={company.id}>
                      {company.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.showInactive}
                    onChange={this.handleShowInactive}
                    color="primary"
                  />
                }
                label="Show Inactive Users"
                style={{ marginRight: 16 }}
              />
            </Box>

            <Box overflow='auto' height="90%">
              <Table size='small' aria-label='users table' stickyHeader={true} id='users-table'>
                <TableHeadSort
                  headers={headers}
                  order={this.state.order}
                  orderBy={this.state.orderBy}
                  onRequestSort={this.handleRequestSort}
                />

                <TableBody>
                  {this.stableSort(this.state.users, this.getSorting(this.state.order, this.state.orderBy)).map((user) =>
                    <TableRow key={user.id} hover={false}>
                      <TableCell>
                        <Link href={`/admin/users/${user.id}/edit`}>
                          <Box>
                            {`${user.last_name}, ${user.first_name}`}
                          </Box>
                        </Link>
                      </TableCell>
                      <TableCell>
                        {user.company_name}
                      </TableCell>
                      <TableCell>
                        {user.active ? 'Active' : 'Inactive'}
                      </TableCell>
                      <TableCell>{humanDateFormat(user.expiration_date)}</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Box>
        </Paper>
      </React.Fragment>
    )
  }
}

Users.propTypes = {
  users: PropTypes.array,
  companyOptions: PropTypes.array
}
