import React, { Component } from "react"
import PropTypes from "prop-types"
import Box from "../Box"
import TextField from "@material-ui/core/TextField"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import InputLabel from "@material-ui/core/InputLabel"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormLabel from "@material-ui/core/FormLabel"
import FormHelperText from "@material-ui/core/FormHelperText"
import Alert from "@material-ui/lab/Alert"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Checkbox from "@material-ui/core/Checkbox"

export default class CollectionForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mode: "new",
      selectedCollectionId: null,
      title: "",
      description: "",
      users: [],
      selectedUsers: []
    }
    this.notifyParentDebounced = this.debounce(this.notifyParent, 300)
  }

  componentWillUnmount() {
    // Clear any pending debounced calls
    if (this.notifyParentDebounced.cancel) {
      this.notifyParentDebounced.cancel()
    }
  }

  debounce = (func, wait) => {
    let timeout
    const debouncedFunc = (...args) => {
      const later = () => {
        clearTimeout(timeout)
        func(...args)
      }
      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
    }
    debouncedFunc.cancel = () => {
      clearTimeout(timeout)
    }
    return debouncedFunc
  }

  componentDidMount() {
    // Initial notification to parent
    this.notifyParent()
    
    // Fetch users for sharing
    $.ajax({
      type: 'GET',
      url: `/users`,
      data: {
        user_company_id: this.props.currentUser.user_company_id,
        user_id: this.props.currentUser.id,
        sector_id: this.props.sector.id
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          users: data.users
        })
      }
    })
  }

  notifyParent = () => {
    const { mode, selectedCollectionId, title, description, selectedUsers } = this.state
    this.props.onChange({
      isExistingCollection: mode === "existing",
      selectedCollectionId: mode === "existing" ? selectedCollectionId : null,
      title: mode === "new" ? title : "",
      description: mode === "new" ? description : "",
      selectedUsers: mode === "new" ? selectedUsers : []
    })
  }

  handleModeChange = (e) => {
    const newMode = e.target.value
    this.setState({ 
      mode: newMode,
      selectedCollectionId: newMode === "existing" && this.props.collections && this.props.collections.length > 0 
        ? this.props.collections[0].value 
        : null
    }, this.notifyParent)
  }

  handleCollectionSelect = (e) => {
    this.setState({ 
      selectedCollectionId: e.target.value 
    }, this.notifyParent)
  }

  handleInputChange = (e) => {
    const { name, value } = e.target
    this.setState({ [name]: value }, () => {
      // Only debounce text input notifications
      if (name === 'title' || name === 'description') {
        this.notifyParentDebounced()
      } else {
        this.notifyParent()
      }
    })
  }

  handleUserToggle = (userId) => {
    const selectedUsers = [...this.state.selectedUsers]
    const index = selectedUsers.indexOf(userId)
    
    if (index === -1) {
      selectedUsers.push(userId)
    } else {
      selectedUsers.splice(index, 1)
    }
    
    this.setState({ selectedUsers }, this.notifyParent)
  }

  // Call this when the form is about to be submitted
  prepareSubmission = () => {
    this.notifyParent()
  }

  render() {
    const hasCollections = this.props.collections && this.props.collections.length > 0

    return(
      <Box p={2}>
        <Box mb={4}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Choose an option</FormLabel>
            <RadioGroup
              value={this.state.mode}
              onChange={this.handleModeChange}
            >
              <FormControlLabel 
                value="new" 
                control={<Radio />} 
                label="Create a new collection" 
              />
              <FormControlLabel 
                value="existing" 
                control={<Radio />} 
                label="Add to an existing collection" 
                disabled={!hasCollections}
              />
            </RadioGroup>
          </FormControl>
        </Box>

        {this.state.mode === "existing" ? (
          <Box mb={3}>
            {hasCollections ? (
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="collection-select-label">Select Collection</InputLabel>
                <Select
                  labelId="collection-select-label"
                  label="Select Collection"
                  value={this.state.selectedCollectionId || ''}
                  onChange={this.handleCollectionSelect}
                  name="existingCollection"
                >
                  {this.props.collections.map(collection => (
                    <MenuItem key={collection.value} value={collection.value}>
                      {collection.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>Choose an existing collection to add the selected products to</FormHelperText>
              </FormControl>
            ) : (
              <Alert severity="info">
                You don't have any collections yet. Create a new collection to get started.
              </Alert>
            )}
          </Box>
        ) : (
          <>
            <Box mb={3}>
              <Typography variant="subtitle1" gutterBottom>
                Create New Collection
              </Typography>
              <Typography variant="body2" color="textSecondary" paragraph>
                Create a new collection to organize and group your selected products
              </Typography>
              <Divider />
            </Box>

            <Box mb={3}>
              <TextField
                label="Collection Title"
                name="title"
                value={this.state.title}
                onChange={this.handleInputChange}
                variant="outlined"
                fullWidth
                helperText="Give your collection a descriptive name"
              />
            </Box>

            <Box mb={4}>
              <TextField
                label="Collection Description"
                name="description"
                value={this.state.description}
                onChange={this.handleInputChange}
                multiline
                rows={4}
                variant="outlined"
                fullWidth
                helperText="Optional: Add details about what this collection is for"
              />
            </Box>

            <Box>
              <Typography variant="subtitle1" gutterBottom>
                Share Collection
              </Typography>
              <Typography variant="body2" color="textSecondary" paragraph>
                Select team members to share this collection with
              </Typography>
              <Divider />
              
              {this.state.users.length > 0 ? (
                <Box mt={2} maxHeight={300} overflow="auto">
                  <List>
                    {this.state.users.map(user => (
                      <ListItem key={user.id} dense>
                        <ListItemText
                          primary={`${user.first_name} ${user.last_name}`}
                          secondary={user.email}
                        />
                        <Checkbox
                          checked={this.state.selectedUsers.includes(user.id)}
                          onChange={() => this.handleUserToggle(user.id)}
                          color="primary"
                        />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              ) : (
                <Box mt={2}>
                  <Alert severity="info">
                    No team members available to share with.
                  </Alert>
                </Box>
              )}
            </Box>
          </>
        )}
      </Box>
    )
  }
}

CollectionForm.propTypes = {
  collections: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  sector: PropTypes.object.isRequired
}
