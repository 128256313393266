import React, { Fragment } from "react"
import { ListItem, ListItemText } from "../../lists"
import Checkbox from "../../Checkbox"
import DeleteButton from "../DeleteButton"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import Box from "../../Box"
import Typography from "@material-ui/core/Typography"
import Link from "../../Link"

export default function TrialListItem(props) {

  function handleCheckboxSelect(e) {
    props.handleCheckboxSelect(props.trial.trial_id, 'activeTrials')
  }

  function handleUnassociateClick(e) {
    props.handleTrialUnassociateClick(props.abstractTrial)
  }

  return(
    <React.Fragment>
      <ListItem role={undefined}>

        {props.forSelection ?
          <Checkbox
            checked={props.checked}
            onClick={handleCheckboxSelect}
          />
        : ''}

        <ListItemText
          primary={
            <Link href={`/admin/trials/${props.trial.trial_id}`} target="_blank">
              {props.trial.acronym || props.trial.short_name}
            </Link>
          }
          secondary={
            <Fragment>
              <Typography
                component="span"
                variant="body2"
                color="textSecondary"
              >
                {props.trial.short_name}
              </Typography>
              <Box>
                {props.trial.nct_id}
              </Box>
            </Fragment>
          }
        />

        <ListItemSecondaryAction>
          {props.handleTrialUnassociateClick ? <DeleteButton onClick={handleUnassociateClick} /> : (props.associated ? 'Associated' : '')}
        </ListItemSecondaryAction>

      </ListItem>
    </React.Fragment>
  )
}

TrialListItem.defaultProps = {
  forSelection: true
}
