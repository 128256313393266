import React, { useState } from "react"
import PropTypes from 'prop-types'
import Box from "../Box"
import Paper from "../Paper"
import Grid from '@material-ui/core/Grid'
import Typography from "@material-ui/core/Typography"
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActionArea from '@material-ui/core/CardActionArea'
import PeopleIcon from '@material-ui/icons/People'
import VisibilityIcon from '@material-ui/icons/Visibility'
import EventIcon from '@material-ui/icons/Event'
import BarChartIcon from '@material-ui/icons/BarChart'
import AddIcon from '@material-ui/icons/Add'
import GetAppIcon from '@material-ui/icons/GetApp'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button'
import { format } from 'date-fns'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import AddUserModal from '../users/AddUserModal'
import { withSnackbar } from '../SnackbarContainer'

const Dashboard = ({
  totalUsers,
  todaysVisits,
  subscriptionEnd,
  sectorUsage,
  recentActivity,
  companySectors,
  usageTrends,
  topActiveUsers,
  leastActiveUsers,
  neverAccessedUsers,
  neverAccessedTotal,
  snackbarShowMessage
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const formatDate = (date) => format(new Date(date), 'yyyy-MM-dd')
  const formatDateTime = (date) => format(new Date(date), 'yyyy-MM-dd HH:mm')

  const StatCard = ({ title, value, icon: Icon, link }) => {
    const cardContent = (
      <CardContent style={{ height: '100%', padding: '16px' }}>
        <Box display="flex" alignItems="center" mb={2}>
          <Icon style={{ marginRight: 8 }} />
          <Typography variant="h6" component="h2">
            {title}
          </Typography>
        </Box>
        <Typography variant="h4" component="p">
          {value}
        </Typography>
      </CardContent>
    )

    return (
      <Card>
        {link ? (
          <CardActionArea component="a" href={link}>
            {cardContent}
          </CardActionArea>
        ) : cardContent}
      </Card>
    )
  }


  const handleInviteUser = (userData) => {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: '/users/invite',
        type: 'POST',
        data: { 
          email: userData.email,
          first_name: userData.firstName,
          last_name: userData.lastName,
          department: userData.department,
          role: userData.role
        },
        success: (response) => {
          setIsModalOpen(false)
          snackbarShowMessage('User invited successfully')
          resolve(response)
        },
        error: (error) => {
          console.error('Error inviting user:', error)
          snackbarShowMessage(error.responseJSON?.error || 'Failed to invite user', 'error')
          reject(error)
        }
      })
    })
  }

  const handleExportMetrics = () => {
    const csvData = [
      ['Metric', 'Value'],
      ['Total Users', totalUsers],
      ['Today\'s Visits', todaysVisits],
      ['Total Sectors', companySectors.length],
      ['Subscription End Date', formatDate(subscriptionEnd)],
      [''],
      ['Sector Usage'],
      ['Sector', 'Visits'],
      ...companySectors.map(sector => [sector.abbr, sectorUsage[sector.abbr] || 0]),
      [''],
      ['Monthly Usage Trends'],
      ['Month', 'Active Users'],
      ...usageTrends.map(trend => [trend.month, trend.active_users])
    ]

    const csvContent = csvData.map(row => row.join(',')).join('\n')
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
    const link = document.createElement('a')
    const url = URL.createObjectURL(blob)
    link.setAttribute('href', url)
    link.setAttribute('download', `dashboard_metrics_${formatDate(new Date())}.csv`)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const chartOptions = {
    title: {
      text: 'Monthly Active Users'
    },
    xAxis: {
      categories: usageTrends.map(trend => trend.month),
      title: {
        text: null
      }
    },
    yAxis: {
      title: {
        text: 'Number of Users'
      },
      min: 0
    },
    tooltip: {
      formatter: function() {
        return `<b>${this.x}</b><br/>Active Users: ${this.y}`
      }
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: true
        },
        enableMouseTracking: true
      }
    },
    series: [{
      name: 'Active Users',
      data: usageTrends.map(trend => trend.active_users),
      color: '#3f51b5'
    }],
    credits: {
      enabled: false
    }
  }

  return (
    <Box p={3}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h4">
              User Management Dashboard
            </Typography>
            <Box>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => setIsModalOpen(true)}
                style={{ marginRight: 8 }}
              >
                Add User
              </Button>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<GetAppIcon />}
                onClick={handleExportMetrics}
              >
                Export Metrics
              </Button>
            </Box>
          </Box>
        </Grid>

        {/* Stats Cards */}
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Total Users"
            value={totalUsers}
            icon={PeopleIcon}
            link="/user_management/users"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Today's Visits"
            value={todaysVisits}
            icon={VisibilityIcon}
            link="/user_management/usage"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Subscription Ends"
            value={subscriptionEnd ? subscriptionEnd : 'N/A'}
            icon={EventIcon}
            link={null}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Total Sectors"
            value={companySectors.length}
            icon={BarChartIcon}
            link="/user_management/usage"
          />
        </Grid>

        {/* Usage Trends */}
        <Grid item xs={12}>
          <Paper>
            <Box p={3}>
              <Typography variant="h6" gutterBottom>
                Usage Trends
              </Typography>
              <Box height={400}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartOptions}
                />
              </Box>
            </Box>
          </Paper>
        </Grid>

        {/* Top Active Users */}
        <Grid item xs={12} md={6}>
          <Paper>
            <Box p={2}>
              <Typography variant="h6" gutterBottom>
                Top Active Users (Last 30 Days)
              </Typography>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>User</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell align="right">Total Visits</TableCell>
                      <TableCell>Last Active</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {topActiveUsers?.map((userData) => (
                      <TableRow key={userData.user.id}>
                        <TableCell>
                          {userData.user.first_name} {userData.user.last_name}
                        </TableCell>
                        <TableCell>{userData.user.email}</TableCell>
                        <TableCell align="right">{userData.visit_count}</TableCell>
                        <TableCell>{formatDateTime(userData.last_active)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Paper>
        </Grid>

        {/* Least Active Users */}
        {/* <Grid item xs={12} md={6}>
          <Paper>
            <Box p={2}>
              <Typography variant="h6" gutterBottom>
                Least Active Users (Last 30 Days)
              </Typography>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>User</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell align="right">Total Visits</TableCell>
                      <TableCell>Last Active</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {leastActiveUsers?.length > 0 ? (
                      leastActiveUsers.map((userData) => (
                        <TableRow key={userData.user.id}>
                          <TableCell>
                            {userData.user.first_name} {userData.user.last_name}
                          </TableCell>
                          <TableCell>{userData.user.email}</TableCell>
                          <TableCell align="right">{userData.visit_count}</TableCell>
                          <TableCell>{userData.last_active ? formatDateTime(userData.last_active) : 'Never'}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={4} align="center">
                          No user activity data available
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Paper>
        </Grid> */}

        {/* Never Accessed Users */}
        <Grid item xs={12} md={6}>
          <Paper>
            <Box p={2}>
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h6">
                  Never Accessed Users
                </Typography>
                {neverAccessedTotal > 5 && (
                  <Button 
                    component='a'
                    to="/user_management/users?filter=never_accessed"
                    size="small"
                    color="primary"
                  >
                    Show All ({neverAccessedTotal})
                  </Button>
                )}
              </Box>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>User</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Created</TableCell>
                      <TableCell>Days Since Creation</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {neverAccessedUsers?.length > 0 ? (
                      neverAccessedUsers.map((user) => {
                        const createdDate = new Date(user.created_at)
                        const daysSinceCreation = Math.floor((new Date() - createdDate) / (1000 * 60 * 60 * 24))
                        return (
                          <TableRow key={user.id}>
                            <TableCell>
                              {user.first_name} {user.last_name}
                            </TableCell>
                            <TableCell>{user.email}</TableCell>
                            <TableCell>{formatDate(user.created_at)}</TableCell>
                            <TableCell align="right">{daysSinceCreation}</TableCell>
                          </TableRow>
                        )
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={4} align="center">
                          All users have accessed the site
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Paper>
        </Grid>

        {/* Today's Sector Usage */}
        <Grid item xs={12} md={6}>
          <Paper>
            <Box p={2}>
              <Typography variant="h6" gutterBottom>
                Today's Sector Usage
              </Typography>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Sector</TableCell>
                      <TableCell align="right">Visits</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {companySectors.map((sector) => (
                      <TableRow key={sector.abbr}>
                        <TableCell>{sector.abbr}</TableCell>
                        <TableCell align="right">{sectorUsage[sector.abbr] || 0}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Paper>
        </Grid>

        {/* Recent Activity */}
        <Grid item xs={12} md={6}>
          <Paper>
            <Box p={2}>
              <Typography variant="h6" gutterBottom>
                Recent Activity
              </Typography>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>User</TableCell>
                      <TableCell>Time</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {recentActivity.map((activity) => (
                      <TableRow key={activity.id}>
                        <TableCell>
                          {activity.user?.first_name} {activity.user?.last_name}
                        </TableCell>
                        <TableCell>{formatDateTime(activity.time)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      <AddUserModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleInviteUser}
      />
    </Box>
  )
}

Dashboard.propTypes = {
  totalUsers: PropTypes.number,
  todaysVisits: PropTypes.number,
  subscriptionEnd: PropTypes.string,
  sectorUsage: PropTypes.object,
  recentActivity: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    time: PropTypes.string,
    user: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string
    })
  })),
  companySectors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    abbr: PropTypes.string
  })),
  usageTrends: PropTypes.arrayOf(PropTypes.shape({
    month: PropTypes.string,
    active_users: PropTypes.number,
    date: PropTypes.string
  })),
  topActiveUsers: PropTypes.arrayOf(PropTypes.shape({
    user: PropTypes.shape({
      id: PropTypes.number,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      email: PropTypes.string
    }),
    visit_count: PropTypes.number,
    last_active: PropTypes.string
  })),
  leastActiveUsers: PropTypes.arrayOf(PropTypes.shape({
    user: PropTypes.shape({
      id: PropTypes.number,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      email: PropTypes.string
    }),
    visit_count: PropTypes.number,
    last_active: PropTypes.string
  })),
  neverAccessedUsers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    created_at: PropTypes.string
  })),
  neverAccessedTotal: PropTypes.number,
  snackbarShowMessage: PropTypes.func
}

export default withSnackbar(Dashboard) 